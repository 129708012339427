import React, { createContext, useMemo } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { selectStatus, selectUser } from "@shared/redux/slices/authSlice";

type AuthProviderState = {
  authStatus: string;
  user: any;
};

const initialState = {
  authStatus: "LOGGED_OUT",
  user: null,
};

const AuthContext = createContext<AuthProviderState>({
  ...initialState,
});

export const AuthProvider = ({ children }: { children: any }) => {
  const authStatus = useSelector(selectStatus);
  const user = useSelector(selectUser);

  const pathname = window.location.pathname;

  const isAuthenticationPage = useMemo(() => {
    return (
      pathname === "/login" ||
      pathname === "/register" ||
      pathname === "/forgot-password" ||
      pathname === "/change-password"
    );
  }, [pathname]);

  if (authStatus === "CONFIRMED" && isAuthenticationPage) {
    return <Navigate to="/" />;
  } else if (authStatus === "LOGGED_OUT" && !isAuthenticationPage) {
    return <Navigate to="/login" />;
  } else if (authStatus === "PENDING_MFA") {
    return <Navigate to="/verify-mfa" />;
  }

  return (
    <AuthContext.Provider
      value={{
        authStatus,
        user,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
