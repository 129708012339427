import { Button, Grid, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useSelector } from "react-redux";
import { UpdateAvatar, UpdateProfile } from "@shared/api";
import AvatarCard from "@shared/components/AvatarCard";
import { Switch } from "@shared/components/input/Switch";
import { useAppDispatch } from "@shared/redux/hooks";
import { login, selectUser } from "@shared/redux/slices/authSlice";
import { getErrorMessage } from "@shared/util/Functions";
import useAlert from "@shared/util/hooks";

export default function ProfileSettings() {
  const { setError, setSuccess } = useAlert();
  const dispatch = useAppDispatch();

  const user = useSelector(selectUser);

  // User Info
  const [firstName, setFirstname] = useState<string>(user.firstName ?? "");
  const [lastName, setLastName] = useState<string>(user.lastName ?? "");
  const [email, setEmail] = useState<string>(user.email ?? "");
  const [username, setUsername] = useState<string>(user.username ?? "");
  const [userInfoError, setUserInfoError] = useState<boolean>(false);

  // Privacy settings
  const [isAccountVisible, setIsAccountVisible] = useState<boolean>(
    user.isVisible ?? false
  );

  const handleUserInfoSubmit = async () => {
    if (
      firstName.length === 0 ||
      lastName.length === 0 ||
      email.length === 0 ||
      username.length === 0
    ) {
      setUserInfoError(true);
      setError("Error: Invalid form submission.");
      return;
    }

    const response = await UpdateProfile({
      firstName,
      lastName,
      email,
      username,
      isVisible: isAccountVisible,
    }).catch((error) => {
      setError(getErrorMessage(error), 5000);
    });

    if (response) {
      setSuccess("Profile successfully updated!");
      dispatch(login({ user: response.data, status: "CONFIRMED" }));
    }
  };

  return (
    <Grid container flexDirection="column" width="40%">
      <Typography variant="h2" color="#060E5E">
        Basic Info
      </Typography>
      <AvatarCard
        title="Edit your photo"
        userAvatarUrl={user.profileImageUrl}
        onFileSelected={async (file) => {
          const response = await UpdateAvatar(file).catch((error) => {
            setError(getErrorMessage(error), 5000);
          });

          if (response) {
            setSuccess("Avatar successfully updated!");
            dispatch(login({ user: response.data, status: "CONFIRMED" }));
          }
        }}
      />
      <Grid item mt={4}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              required
              type="text"
              label="First Name"
              value={firstName}
              onChange={(event) => {
                setFirstname(event.target.value);
                setUserInfoError(false);
              }}
              error={userInfoError && firstName.length === 0}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              type="text"
              label="Last Name"
              value={lastName}
              onChange={(event) => {
                setLastName(event.target.value);
                setUserInfoError(false);
              }}
              error={userInfoError && lastName.length === 0}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              type="text"
              label="Email"
              value={email}
              onChange={(event) => {
                setEmail(event.target.value);
                setUserInfoError(false);
              }}
              error={userInfoError && email.length === 0}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              required
              type="text"
              label="Username"
              value={username}
              onChange={(event) => {
                setUsername(event.target.value);
                setUserInfoError(false);
              }}
              error={userInfoError && username.length === 0}
            />
          </Grid>
          <Grid container mt={2}>
            <Grid item xs={12}>
              <Grid container alignItems="center">
                <Grid item>
                  <Switch
                    checked={isAccountVisible}
                    onChange={(event) => {
                      setIsAccountVisible(event.target.checked);
                    }}
                    value="checked"
                  />
                </Grid>
                <Grid item>Make my account visible to other users</Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={2}>
        <Grid item>
          <Button
            variant="primary"
            style={{
              width: "187px",
              height: "48px",
            }}
            onClick={() => handleUserInfoSubmit()}
          >
            Save changes
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="stroke"
            style={{
              width: "187px",
              height: "48px",
            }}
          >
            Cancel
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
