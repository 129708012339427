import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { RegisterUser } from "@shared/api/node";
import useAlert from "@shared/util/hooks";
import { Link, useNavigate } from "react-router-dom";
import { getErrorMessage } from "@shared/util/Functions";

export default function Register() {
  const navigate = useNavigate();
  const { setError, setSuccess } = useAlert();

  // State Values
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [allowEmail, setAllowEmail] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);

  const handleSubmit = async () => {
    if (email.length === 0 || password.length === 0) {
      setError("Error: You must enter an email and password to register.");
      setHasError(true);
      return;
    }

    if (!allowEmail) {
      setError(
        "Error: You must agree to recieve email communication to register."
      );
      return;
    }

    let response = await RegisterUser({
      email: email,
      password: password,
    }).catch((error) => {
      setError(getErrorMessage(error), 5000);
    });

    if (response) {
      setSuccess("Registration successful!");
      navigate("/login");
    }
  };

  useEffect(() => {
    if (email || password) {
      setHasError(false);
    }
  }, [email, password]);

  return (
    <Grid
      container
      justifyContent="center"
      style={{
        width: "100vw",
        height: "100vh",
      }}
    >
      <Grid item md={6} sx={{ display: { xs: "none", md: "block" } }}>
        <Grid
          container
          height="100%"
          style={{
            backgroundImage: "url(/login_graphic.png)",
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
          justifyContent="center"
          alignItems="center"
        >
          <img
            src="logo_colored_dark.svg"
            alt="OpenSigil Logo"
            width="250px"
            style={{
              paddingBottom: "10em",
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={10} md={6}>
        <Grid
          container
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          style={{
            width: "100%",
            height: "100%",
          }}
          spacing={4}
        >
          <Grid item md={8} lg={6}>
            <Grid container textAlign="center" flexDirection="column">
              <Typography variant="h1" mt={4} color="#060E5E">
                Registration
              </Typography>
              <Typography variant="body2" mt={2} color="#50578F">
                Sign up with your email adress to get started.
                <br /> Already have an account?{" "}
                <Link to="/login">Log in now</Link>
              </Typography>
            </Grid>
            <Grid container spacing={2} mt={3}>
              <Grid item xs={12}>
                <TextField
                  required
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                  fullWidth
                  id="email"
                  label="Email Address"
                  autoComplete="email"
                  error={hasError && email.length === 0}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                  fullWidth
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  error={hasError && password.length === 0}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={allowEmail}
                      onChange={(event) => setAllowEmail(event.target.checked)}
                      color="primary"
                    />
                  }
                  label="I agree to receive communication via email."
                />
              </Grid>
            </Grid>
            <Button
              onClick={() => handleSubmit()}
              fullWidth
              variant="primary"
              sx={{ mt: 3, mb: 2 }}
              style={{
                height: "40px",
              }}
            >
              Sign Up
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
