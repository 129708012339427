import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";

export default function ChangePassword() {
  const [email, setEmail] = useState<string>("");

  const handleSubmit = async () => {};

  return (
    <Grid
      container
      style={{
        width: "100vw",
        height: "100vh",
      }}
      justifyContent="center"
    >
      <Grid item md={6} sx={{ display: { xs: "none", md: "block" } }}>
        <Grid
          container
          height="100%"
          style={{
            backgroundImage: "url(/login_graphic.png)",
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
          justifyContent="center"
          alignItems="center"
        >
          <img
            src="logo_colored_dark.svg"
            alt="OpenSigil Logo"
            width="250px"
            style={{
              paddingBottom: "10em",
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={10} md={6}>
        <Grid
          container
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          style={{
            width: "100%",
            height: "100%",
          }}
          spacing={4}
        >
          <Grid item md={8} lg={6}>
            <Grid container textAlign="center" flexDirection="column">
              <Typography variant="h1" mt={4} color="#060E5E">
                Change Password
              </Typography>
              <Typography variant="body2" mt={2} color="#50578F">
                Enter a new password for your account. Don't have an account?
                <br />
                <Link to="/register">Sign up now</Link>
              </Typography>
            </Grid>
            <Grid container mt={1}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                placeholder="Email address"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                placeholder="Email address"
                autoComplete="email"
                autoFocus
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
              <Button
                type="submit"
                fullWidth
                variant="primary"
                sx={{ mt: 3, mb: 2 }}
                style={{
                  height: "40px",
                }}
                onClick={handleSubmit}
              >
                Change password
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
