import { Menu, MenuItem, Grid, Typography, Tooltip } from "@mui/material";
import { DownloadFile } from "@shared/api/django";
import { useState } from "react";
import FilePropertiesDialog from "./FilePropertiesDialog";
import { DeleteFile, FavoriteFile } from "@shared/api";
import useAlert from "@shared/util/hooks";
import { useSelector } from "react-redux";
import { selectUser } from "@shared/redux/slices/authSlice";
import FileSharingDialog from "./FileSharingDialog";
import FileEditingDialog from "./FileEditingDialog";
import DownloadIcon from "@graphics/file_menu/download.svg";
import CopyLinkIcon from "@graphics/file_menu/copy_link.svg";
import ShareIcon from "@graphics/file_menu/share.svg";
import EditIcon from "@graphics/file_menu/edit.svg";
import TrashIcon from "@graphics/file_menu/trash.svg";
import FavoriteIcon from "@graphics/file_menu/favorite.svg";
import RenameIcon from "@graphics/file_menu/rename.svg";
import PropertiesIcon from "@graphics/file_menu/properties.svg";

interface FileSelectedMenupProps {
  anchorEl: HTMLElement | null;
  onClose: () => void;
  file: any;
  open: boolean;
  reloadFile: () => void;
  reloadAllFiles: () => void;
}

enum DialogType {
  Properties = "PROPERTIES",
  Sharing = "SHARING",
  Editing = "EDITING",
}

export default function FileSelectedMenu(props: FileSelectedMenupProps) {
  const [dialog, setDialog] = useState<null | DialogType>(null);
  const { setSuccess } = useAlert();
  const user = useSelector(selectUser);

  const entries = [
    {
      label: "Download",
      icon: <img src={DownloadIcon} alt="" />,
      onClick: async () => {
        props.onClose();
        const file = props.file;

        const response = await DownloadFile(file.id);

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");

        link.href = url;
        link.setAttribute("download", file.name);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link?.parentNode?.removeChild(link);
      },
    },
    {
      label: "Copy Link",
      icon: <img src={CopyLinkIcon} alt="" />,
    },
    {
      label: "Share",
      icon: <img src={ShareIcon} alt="" />,
      onClick: () => {
        setDialog(DialogType.Sharing);
        props.onClose();
      },
    },
    {
      label: "Edit",
      icon: <img src={EditIcon} alt="" />,
      onClick: () => {
        setDialog(DialogType.Editing);
        props.onClose();
      },
    },
    {
      label: "Delete",
      icon: <img src={TrashIcon} alt="" />,
      onClick: async () => {
        props.onClose();
        const file = props.file;

        await DeleteFile(file.id);
        setSuccess(`Successfully deleted file.`);
        props.reloadAllFiles();
      },
    },
    {
      label: "Favorite",
      icon: <img src={FavoriteIcon} alt="" />,
      onClick: async () => {
        props.onClose();
        const file = props.file;

        let userFile = file.users.find(
          (userFile: any) => userFile.userId === user.id
        );

        await FavoriteFile(file.id, !userFile.isFavorite);
        setSuccess(
          `Successfully ${
            !userFile.isFavorite ? "added" : "removed"
          } the file ${!userFile.isFavorite ? "to" : "from"} your favorites.`
        );
        props.reloadAllFiles();
      },
    },
    {
      label: "Rename",
      icon: <img src={RenameIcon} alt="" />,
    },
    {
      label: "Properties",
      icon: <img src={PropertiesIcon} alt="" />,
      onClick: () => {
        setDialog(DialogType.Properties);
        props.onClose();
      },
    },
  ];

  return (
    <>
      <Menu
        open={props.open}
        anchorEl={props.anchorEl}
        onClose={props.onClose}
        MenuListProps={{
          style: {
            backgroundColor: "#F8F8F8",
          },
        }}
      >
        {entries.map((entry) => {
          return (
            <Tooltip title={entry.onClick != null ? "" : "Coming soon"}>
              <MenuItem
                style={{
                  width: "12em",
                }}
                onClick={() => {
                  if (entry.onClick) {
                    entry.onClick();
                  }
                }}
              >
                <Grid container alignItems="center">
                  <Grid item>
                    <Grid container justifyContent="center" alignItems="center">
                      {entry.icon}
                      <Typography
                        variant="body2"
                        style={{
                          marginLeft: "1em",
                          fontSize: "14px",
                        }}
                      >
                        {entry.label}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </MenuItem>
            </Tooltip>
          );
        })}
      </Menu>
      <FilePropertiesDialog
        file={props.file}
        open={dialog === DialogType.Properties}
        onClose={() => {
          setDialog(null);
          props.reloadAllFiles();
        }}
        reloadFile={props.reloadFile}
      />

      <FileSharingDialog
        file={props.file}
        open={dialog === DialogType.Sharing}
        onClose={() => {
          setDialog(null);
          props.reloadAllFiles();
        }}
        reloadFile={props.reloadFile}
      />

      <FileEditingDialog
        file={props.file}
        open={dialog === DialogType.Editing}
        onClose={() => {
          setDialog(null);
          props.reloadAllFiles();
        }}
        reloadFile={props.reloadFile}
      />
    </>
  );
}
