import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import AvatarCard from "@shared/components/AvatarCard";
import { CreateProfile as CreateProfileAPI } from "@shared/api";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "@shared/redux/hooks";
import { login } from "@shared/redux/slices/authSlice";
import useAlert from "@shared/util/hooks";
import { getErrorMessage } from "@shared/util/Functions";

export default function CreateProfile() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { setError, setSuccess } = useAlert();

  // Form values
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [username, setUsername] = useState<string>("");
  const [avatar, setAvatar] = useState<File>();

  const handleSubmit = async () => {
    if (
      firstName.length === 0 ||
      lastName.length === 0 ||
      username.length === 0
    ) {
      setError("Error: You must enter a first name, last name, and username.");
      return;
    }

    let response = await CreateProfileAPI({
      firstName,
      lastName,
      username,
      avatar,
    }).catch((error) => {
      setError(getErrorMessage(error), 5000);
    });

    if (response) {
      setSuccess("Profile successfully created!");
      dispatch(login({ user: response.data, status: "CONFIRMED" }));
      navigate("/");
    }
  };

  return (
    <Grid
      container
      style={{
        width: "100vw",
        height: "100vh",
        backgroundColor: "#F5F6FE",
        backgroundImage:
          "url('/icons/illustrations/left_side_green_cards.svg'), url('/icons/illustrations/right_side_green_cards.svg')",
        backgroundRepeat: "no-repeat, no-repeat",
        backgroundPosition: "left bottom 50px, right bottom 50px",
      }}
      justifyContent="center"
      alignItems="center"
    >
      <Grid item xs={8} sm={8} md={4} lg={2.5}>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{
            backgroundColor: "white",
            borderRadius: "25px",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
          }}
        >
          <Grid
            item
            xs={12}
            style={{
              margin: "2em",
            }}
          >
            <Grid container textAlign="center" flexDirection="column">
              <Typography variant="h1" color="#060E5E">
                Welcome!
              </Typography>
              <Typography variant="h1" color="#060E5E">
                Let's create your profile
              </Typography>
            </Grid>
            <AvatarCard
              title="Add a photo"
              onFileSelected={(file) => setAvatar(file)}
            />
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="firstName"
                  placeholder="First Name"
                  name="firstName"
                  autoComplete="given-name"
                  autoFocus
                  value={firstName}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setFirstName(event.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="lastName"
                  placeholder="Last Name"
                  name="lastName"
                  autoComplete="family-name"
                  value={lastName}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setLastName(event.target.value);
                  }}
                />
              </Grid>
            </Grid>
            <TextField
              margin="normal"
              required
              fullWidth
              name="username"
              placeholder="Username"
              type="text"
              id="username"
              value={username}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setUsername(event.target.value);
              }}
            />
            <Button
              fullWidth
              variant="primary"
              sx={{ mt: 3, mb: 2 }}
              style={{
                height: "40px",
              }}
              onClick={() => handleSubmit()}
            >
              Continue
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
