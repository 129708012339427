export enum FileView {
  ALL = "ALL",
  MY_FILES = "MY_FILES",
  SHARED = "SHARED",
  FAVORITE = "FAVORITE",
}

export const DragTypes = {
  FILE: "FILE",
  FOLDER: "FOLDER",
};
