import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface AuthState {
  status: "LOGGED_OUT" | "CONFIRMED" | "PENDING_MFA";
  user: any;
}

const initialState: AuthState = {
  status: "LOGGED_OUT",
  user: null,
};

export const authSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    login: (state, action) => {
      state.status = action.payload.status;
      state.user = action.payload.user;
    },
    logout: (state) => {
      state.status = "LOGGED_OUT";
      state.user = null;
    },
  },
});

export const { login, logout } = authSlice.actions;

export const selectStatus = (state: RootState) => state.auth.status;
export const selectUser = (state: RootState) => state.auth.user;

export default authSlice.reducer;
