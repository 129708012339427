import { AvatarGroup, Grid, Typography } from "@mui/material";
import UserAvatar from "../../UserAvatar";
import LockIcon from "@mui/icons-material/Lock";

interface FileAccessAvatarListProps {
  file: any;
}

export default function FileAccessAvatarList(props: FileAccessAvatarListProps) {
  let nonOwners = props.file.users.filter(
    (user: any) => user.permission !== "OWNER"
  );

  if (nonOwners.length === 0) {
    return (
      <Grid container>
        <LockIcon />
        <Typography ml={1}>Only Me</Typography>
      </Grid>
    );
  }

  return (
    <Grid container>
      <AvatarGroup max={4}>
        {nonOwners.map((nonOwner: any) => {
          return <UserAvatar user={nonOwner.user}></UserAvatar>;
        })}
      </AvatarGroup>
    </Grid>
  );
}
