import React from "react";
import {
  Dialog,
  DialogTitle,
  Grid,
  Typography,
  Divider,
  Button,
  TextField,
} from "@mui/material";
import CreateFolderIcon from "@graphics/final/filled/folder-plus.svg";

interface Props {
  open: boolean;
  folderName: string;
  onCancel: () => void;
  onContinue: () => void;
  setFolderName: (folderName: string) => void;
}

export default function CreateFolderModal(props: Props) {
  const { open, folderName, onCancel, onContinue, setFolderName } = props;

  return (
    <>
      <Dialog
        open={open}
        onClose={() => onCancel()}
        PaperProps={{
          style: {
            borderRadius: "8px",
            boxShadow: "0px 19px 40px rgba(0, 0, 0, 0.06)",
          },
        }}
      >
        <DialogTitle
          style={{
            borderBottom: "1px solid #E6E7EF",
            padding: "16px 24px",
            background:
              "linear-gradient(107.39deg, #FFFFFF -0.77%, #EEF2FE 100%)",
          }}
        >
          <Grid container spacing={1}>
            <Grid item>
              <img src={CreateFolderIcon} alt="" />
            </Grid>
            <Grid item>
              <Typography variant="h3" fontWeight={600} color="#060E5E">
                Create Folder
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <Grid container flexDirection="column" padding={3}>
          <TextField
            hiddenLabel
            variant="filled"
            placeholder="Folder name"
            value={folderName}
            onChange={(event) => setFolderName(event.target.value)}
          />
          <Divider
            style={{
              backgroundColor: "E6E7EF",
              width: "100%",
              marginTop: "1em",
            }}
          />
          <Grid container mt={2} justifyContent="flex-end" spacing={2}>
            <Grid item>
              <Button
                variant="primary"
                style={{
                  height: "48px",
                  width: "144px",
                }}
                onClick={() => onContinue()}
              >
                Create
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
}
