import axios from "axios";
import { store } from "@shared/redux/store";
import { logout } from "@shared/redux/slices/authSlice";

const instance = axios.create({
  baseURL: process.env.REACT_APP_DJANGO_URL,
  withCredentials: true,
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.status === 401) {
      store.dispatch(logout());
    }

    return Promise.reject(error);
  }
);

export async function UploadFile(file: any, userId: string) {
  let form = new FormData();
  form.append("data", file);
  form.append("userId", userId);

  return await instance.post("/api/sigil/encrypt", form);
}

export async function DownloadFile(fileId: number) {
  return await instance.post("/api/sigil/decrypt", null, {
    headers: {
      fileid: fileId,
    },
    responseType: "arraybuffer",
  });
}
