import { createTheme } from "@mui/material/styles";

export default createTheme({
  typography: {
    h1: {
      fontFamily: "Poppins",
      fontSize: "24px",
      fontWeight: 500,
      lineHeight: "140%",
      letterSpacing: 0,
    },
    h2: {
      fontFamily: "Poppins",
      fontSize: "20px",
      fontWeight: 500,
      lineHeight: "140%",
    },
    h3: {
      fontFamily: "Poppins",
      fontSize: "18px",
      fontWeight: 500,
      lineHeight: "140%",
    },
    h4: {
      fontFamily: "Poppins",
      fontSize: "14px",
      lineHeight: "150%",
    },
    h5: {
      fontFamily: "Poppins",
    },
    h6: {
      fontFamily: "Poppins",
    },
    body1: {
      fontFamily: "Inter",
      fontSize: "16px",
      lineHeight: "140%",
    },
    body2: {
      fontFamily: "Inter",
      fontSize: "14px",
      lineHeight: "145%",
    },
    body3: {
      fontFamily: "Inter",
      fontSize: "12px",
      lineHeight: "150%",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => {
          switch (ownerState.variant) {
            case "primary":
              return {
                "&.Mui-disabled": {
                  background: "#CDCFDF",
                  color: "#A8ABC7",
                },
              };
            case "medium":
              return {
                "&.Mui-disabled": {
                  color: "#A8ABC7",
                },
                "&:hover": {
                  background: "#060E5E",
                },
              };
            case "text":
              return {
                "&.Mui-disabled": {
                  color: "#C1C3D7",
                },
                "&:hover": {
                  background: "transparent",
                  color: "#5295fd",
                },
              };
            default:
              return;
          }
        },
      },
      variants: [
        {
          props: {
            variant: "primary",
          },
          style: {
            backgroundImage: "url('/button/default-gradient.svg')",
            backgroundSize: "100% 100%",
            borderRadius: "100px",
            color: "white",
            padding: 0,
            textTransform: "none",
          },
        },
        {
          props: { variant: "stroke" },
          style: {
            border: "1px solid #5295FD",
            borderRadius: "100px",
            color: "rgba(82, 149, 253, 1)",
            padding: 0,
            textTransform: "none",
          },
        },
        {
          props: { variant: "medium" },
          style: {
            backgroundColor: "#8287AF",
            borderRadius: "24px",
            color: "white",
            padding: 0,
            textTransform: "none",
          },
        },
        {
          props: { variant: "light" },
          style: {
            border: "1px solid #E6E7EF",
            backgroundColor: "#F8F8F8",
            borderRadius: "8px",
            color: "#50578F",
            width: "104px",
            height: "34px",
            fontFamily: "Inter",
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "145%",
            padding: 0,
            textTransform: "none",
          },
        },
        {
          props: { variant: "text" },
          style: {
            color: "#060E5E",
            width: "59px",
            height: "20px",
            fontFamily: "Inter",
            fontWeight: 600,
            fontSize: "14px",
            lineHeight: "145%",
            padding: 0,
            textTransform: "none",
          },
        },
      ],
    },
    MuiIconButton: {
      styleOverrides: {
        root: ({ ownerState }) => {
          return {
            "&.Mui-disabled": {
              color: "#C1C3D7",
            },
            "&:hover": {
              background: "transparent",
              color: "#5295fd",
            },
          };
        },
      },
    },
  },
});

export * from "./modules";
