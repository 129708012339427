/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import { Grid, Typography } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { useSelector } from "react-redux";
import { selectUser } from "@shared/redux/slices/authSlice";
import Divider from "@mui/material/Divider/Divider";
import FormGroup from "@mui/material/FormGroup/FormGroup";
import { Switch } from "@shared/components/input/Switch";
import TextField from "@mui/material/TextField/TextField";
import Button from "@mui/material/Button/Button";

export default function OrganizationSettings() {
  const user = useSelector(selectUser);

  // General Settings
  const [generalSharing, setGeneralSharing] = useState<string>("ANYONE");
  const [approvedSharingList, setApprovedSharingList] = useState<any[]>();
  const [editExternalFolders, setEditExternalFolders] =
    useState<boolean>(false);

  // Link settings
  const [linkSharingExternally, setLinkSharingExternally] =
    useState<string>("FORBIDDEN");
  const [defaultSharedLinkPrivacy, setDefaultSharedLinkPrivacy] =
    useState<string>("TEAM");
  const [defaultExpirationTime, setDefaultExpirationTime] = useState<number>(3);

  return (
    <>
      <Grid container flexDirection="column" width="40%">
        <Typography variant="h2" color="#060E5E">
          General sharing settings
        </Typography>
        <Grid mt={2}>
          <FormControl>
            <FormLabel>
              <Typography variant="body2" color="#060E5E" fontWeight={600}>
                Team Sharing
              </Typography>
            </FormLabel>
            <RadioGroup
              row
              value={generalSharing}
              onChange={(event) => setGeneralSharing(event.target.value)}
            >
              <FormControlLabel
                value="ANYONE"
                control={<Radio />}
                label={<Typography variant="body2">Anyone</Typography>}
              />
              <FormControlLabel
                value="TEAM_AND_APPROVED"
                control={<Radio />}
                label={
                  <Typography variant="body2">Team and approved</Typography>
                }
              />
              <FormControlLabel
                value="TEAM"
                control={<Radio />}
                label={<Typography variant="body2">Only team</Typography>}
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid mt={2}>
          <Typography variant="body2" fontWeight={600} color="#060E5E">
            Approved Sharing List
          </Typography>
          <Grid container mt={1}>
            <TextField
              label="Enter username or email"
              variant="outlined"
              size="small"
            />
            <Grid item ml={1}>
              <Button
                variant="medium"
                style={{
                  height: "100%",
                  width: "124px",
                }}
              >
                Add
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid mt={2}>
          <FormGroup>
            <FormControlLabel
              control={<Switch defaultChecked />}
              label={
                <Typography variant="body2">
                  Permission for team members to edit external folders
                </Typography>
              }
            />
          </FormGroup>
        </Grid>
      </Grid>
      <Divider
        style={{
          backgroundColor: "E6E7EF",
          width: "25%",
          marginTop: "2em",
        }}
      />
      <Grid container flexDirection="column" width="40%" mt={4}>
        <Typography variant="h2" color="#060E5E">
          Link sharing settings
        </Typography>
        <Grid mt={2}>
          <FormControl>
            <FormLabel>
              <Typography variant="body2" color="#060E5E" fontWeight={600}>
                Sharing links externally
              </Typography>
            </FormLabel>
            <RadioGroup
              row
              value={linkSharingExternally}
              onChange={(event) => setLinkSharingExternally(event.target.value)}
            >
              <FormControlLabel
                value="ANYONE"
                control={<Radio />}
                label={<Typography variant="body2">Anyone</Typography>}
              />
              <FormControlLabel
                value="TEAM_AND_APPROVED"
                control={<Radio />}
                label={
                  <Typography variant="body2">Team and approved</Typography>
                }
              />
              <FormControlLabel
                value="FORBIDDEN"
                control={<Radio />}
                label={<Typography variant="body2">Forbidden</Typography>}
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid mt={2}>
          <FormGroup>
            <FormControlLabel
              control={<Switch defaultChecked />}
              label={
                <Typography variant="body2">
                  Require a password to access internally shared links
                </Typography>
              }
            />
          </FormGroup>
        </Grid>

        <Grid mt={2}>
          <FormControl>
            <FormLabel>
              <Typography variant="body2" color="#060E5E" fontWeight={600}>
                Default shared link privacy
              </Typography>
            </FormLabel>
            <RadioGroup
              row
              value={defaultSharedLinkPrivacy}
              onChange={(event) =>
                setDefaultSharedLinkPrivacy(event.target.value)
              }
            >
              <FormControlLabel
                value="ANYONE"
                control={<Radio />}
                label={<Typography variant="body2">Anyone</Typography>}
              />
              <FormControlLabel
                value="TEAM"
                control={<Radio />}
                label={<Typography variant="body2">Team only</Typography>}
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid mt={2}>
          <Typography variant="body2" fontWeight={600} color="#060E5E">
            Default link expiration time
          </Typography>
          <Grid container mt={1} alignItems="center">
            <TextField variant="outlined" size="small" />
            <Grid item ml={1}>
              <Typography variant="body2" color="#8387AF">
                days
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Grid mt={2}>
          <FormGroup>
            <FormControlLabel
              control={<Switch defaultChecked />}
              label={
                <Typography variant="body2">
                  Allow users to remove/change default expiration time
                </Typography>
              }
            />
          </FormGroup>
        </Grid>
      </Grid>
      <Divider
        style={{
          backgroundColor: "E6E7EF",
          width: "25%",
          marginTop: "1em",
        }}
      />
      <Grid container flexDirection="column" width="40%" mt={4}>
        <Typography variant="h2" color="#060E5E">
          Organization wallet id
        </Typography>
        <Grid mt={2}>
          <TextField variant="outlined" size="small" />
        </Grid>
      </Grid>
    </>
  );
}
