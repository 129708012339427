import React from "react";
import { Avatar } from "@mui/material";

interface UserAvatarProps {
  user: any;
  size?: "MEDIUM" | "LARGE";
}

const sizes = {
  MEDIUM: {
    width: 40,
    height: 40,
  },
  LARGE: {
    width: 56,
    height: 56,
  },
};

const UserAvatar = (props: UserAvatarProps) => {
  const { user, size = "MEDIUM" } = props;

  const profileImageUrl = user.profileImageUrl;
  const fullName = user.firstName + " " + user.lastName;
  const initials = user.firstName[0] + user.lastName[0];

  let width = sizes[size].width;
  let height = sizes[size].height;

  if (profileImageUrl) {
    return (
      <Avatar
        alt={fullName}
        src={profileImageUrl}
        sx={{ width: width, height: height }}
      />
    );
  }

  return <Avatar sx={{ width: width, height: height }}>{initials}</Avatar>;
};

export default UserAvatar;
