import axios from "axios";
import {
  CreateProfileType,
  LoginType,
  RegisterType,
  UpdateProfileType,
  FileView,
} from "@shared/types";
import { store } from "@shared/redux/store";
import { logout } from "@shared/redux/slices/authSlice";

const instance = axios.create({
  baseURL: process.env.REACT_APP_NODE_URL,
  withCredentials: true,
});

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.status === 401) {
      store.dispatch(logout());
    }

    return Promise.reject(error);
  }
);

export async function RegisterUser(input: RegisterType) {
  return await instance.post("/api/auth/register", input);
}

export async function LoginUser(input: LoginType) {
  return await instance.post("/api/auth/login", input);
}

export async function LogoutUser() {
  return await instance.post("/api/auth/logout");
}

export async function ConfirmEmail(token: string) {
  return await instance.post("/api/auth/confirm-email", {
    token: token,
  });
}

export async function UpdatePassword(password: string, newPassword: string) {
  return await instance.post("/api/user/change-password", {
    password,
    newPassword,
  });
}

export async function SetupMFA() {
  return await instance.post("/api/user/setup-mfa");
}

export async function ConfirmMFA(token: string) {
  return await instance.post("/api/user/confirm-mfa", {
    token,
  });
}

export async function RemoveMFA(token: string) {
  return await instance.post("/api/user/remove-mfa", {
    token,
  });
}

export async function VerifyMFA(token: string) {
  return await instance.post("/api/auth/verify-mfa", {
    token,
  });
}

export async function CreateProfile(input: CreateProfileType) {
  let form = new FormData();

  form.append("firstName", input.firstName);
  form.append("lastName", input.lastName);
  form.append("username", input.username);
  if (input.avatar) {
    form.append("avatar", input.avatar);
  }

  return await instance.post("/api/auth/create-profile", form);
}

export async function UpdateAvatar(avatar: File | undefined) {
  let form = new FormData();

  if (avatar) {
    form.append("avatar", avatar);
  }

  return await instance.post("/api/user/update-avatar", form);
}

export async function UpdateProfile(input: UpdateProfileType) {
  return await instance.post("/api/user/update-profile", input);
}

export async function GetFiles(fileView: FileView, folderId?: string) {
  return await instance.get("/api/file/", {
    params: {
      fileView: fileView.toString(),
      folderId,
    },
  });
}

export async function GetFile(fileId: number) {
  return await instance.get(`/api/file/${fileId}`);
}

export async function DeleteFile(fileId: number) {
  return await instance.delete(`/api/file/${fileId}`);
}

export async function MoveFile(
  isFolder: boolean,
  sourceId: number,
  targetId: number
) {
  return await instance.post(`/api/file/move`, {
    isFolder,
    sourceId,
    targetId,
  });
}

export async function CreateFolder(name: string) {
  return await instance.post(`/api/folder/`, {
    name,
  });
}

export async function GetUser() {
  return await instance.get("/api/user/");
}

export async function GetContacts() {
  return await instance.get("/api/user/contacts");
}

export async function GetPendingContacts() {
  return await instance.get("/api/user/contacts/pending");
}

export async function CreateContact(contactUser: string) {
  return await instance.post("/api/user/contacts", {
    contactUser: contactUser,
  });
}

export async function DeleteContact(contactId: number) {
  return await instance.delete("/api/user/contacts", {
    data: {
      contactId: contactId,
    },
  });
}

export async function AcceptContact(userId: number) {
  return await instance.post("/api/user/contacts/accept", {
    userId,
  });
}

export async function RejectContact(userId: number) {
  return await instance.post("/api/user/contacts/reject", {
    userId,
  });
}

export async function AddFileTag(fileId: number, value: string) {
  return await instance.post(`/api/file/${fileId}/tags/`, {
    value: value,
  });
}

export async function RemoveFileTag(fileId: number, tagId: number) {
  return await instance.delete(`/api/file/${fileId}/tags/${tagId}`);
}

export async function FavoriteFile(fileId: number, isFavorite: boolean) {
  return await instance.post(`/api/file/${fileId}/favorite`, {
    isFavorite: isFavorite,
  });
}
