import React from "react";
import { Grid, Typography } from "@mui/material";
import DeleteContactIcon from "@graphics/illustrations/delete_contact.svg";

export default function NoContacts() {
  return (
    <Grid container justifyContent="center" alignItems="center" height="50vh">
      <Grid item>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <Grid item>
            <img src={DeleteContactIcon} alt="" />
          </Grid>
          <Grid item>
            <Typography variant="h1" color="#060E5E">
              No contacts added yet :(
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
