import { Button, Chip, Dialog, Divider, Grid, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  AcceptContact,
  DeleteContact,
  GetContacts,
  GetPendingContacts,
  RejectContact,
} from "@shared/api";
import TabPanel from "@shared/components/TabPanel";
import { selectUser } from "@shared/redux/slices/authSlice";
import useAlert from "@shared/util/hooks";
import ContactList from "./contactList";
import PendingList from "./pendingList";
import DeleteContactIcon from "@graphics/illustrations/delete_contact.svg";

export default function ContactsPage() {
  const { setSuccess, setError } = useAlert();
  const user = useSelector(selectUser);

  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [contacts, setContacts] = useState<any[] | null>(null);
  const [requests, setRequests] = useState<any[] | null>(null);

  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<any>();

  const loadContacts = useCallback(async () => {
    setContacts(null);
    let response = await GetContacts();

    let contacts = [];

    for (let i = 0; i < response.data.length; i++) {
      let item = response.data[i];
      if (item.userId === user.id) {
        contacts.push(item.contact);
      } else if (item.contactId === user.id) {
        contacts.push(item.user);
      }
    }

    setContacts(contacts);
  }, [user.id]);

  const loadRequests = useCallback(async () => {
    setContacts(null);
    const response = await GetPendingContacts();

    const contacts = [];

    for (let i = 0; i < response.data.length; i++) {
      let item = response.data[i];
      if (item.userId === user.id) {
        contacts.push(item.contact);
      } else if (item.contactId === user.id) {
        contacts.push(item.user);
      }
    }

    setRequests(contacts);
  }, [user.id]);

  useEffect(() => {
    loadContacts();
    loadRequests();
  }, [loadContacts, loadRequests]);

  return (
    <>
      <Grid container flexDirection="column" px={3} pt={3}>
        <Grid item>
          <Grid container spacing={2}>
            <Grid item>
              <Button
                variant="text"
                style={{
                  width: "100%",
                  height: "20px",
                }}
                onClick={() => setSelectedTab(0)}
              >
                <Typography
                  variant="h2"
                  textTransform="none"
                  color={selectedTab === 0 ? "#060E5E" : "#50578F"}
                  mr={1}
                >
                  Contact List
                </Typography>
                <Chip
                  label={contacts?.length ?? 0}
                  sx={{
                    "& .MuiChip-label": {
                      fontWeight: "bold",
                      color: selectedTab === 0 ? "white" : "#50578F",
                    },
                  }}
                  style={{
                    backgroundColor: selectedTab === 0 ? "#5295FD" : "#F5F6FE",
                  }}
                  size="small"
                />
              </Button>
            </Grid>
            <Grid item>
              <Divider
                orientation="vertical"
                style={{
                  backgroundColor: "E6E7EF",
                  width: "5px",
                  height: "30px",
                }}
              />
            </Grid>
            <Grid item>
              <Button
                variant="text"
                style={{
                  width: "100%",
                  height: "20px",
                }}
                onClick={() => setSelectedTab(1)}
              >
                <Typography
                  variant="h2"
                  textTransform="none"
                  color={selectedTab === 1 ? "#060E5E" : "#50578F"}
                  mr={1}
                >
                  Pending Requests
                </Typography>
                <Chip
                  label={requests?.length ?? 0}
                  sx={{
                    "& .MuiChip-label": {
                      fontWeight: "bold",
                      color: selectedTab === 1 ? "white" : "#50578F",
                    },
                  }}
                  style={{
                    backgroundColor: selectedTab === 1 ? "#5295FD" : "#F5F6FE",
                  }}
                  size="small"
                />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <TabPanel value={selectedTab} index={0}>
        <ContactList
          contacts={contacts}
          onDeleteUser={(user) => {
            setSelectedUser(user);
            setDeleteModalOpen(true);
          }}
        />
      </TabPanel>
      <TabPanel value={selectedTab} index={1}>
        <PendingList
          contacts={requests}
          onRejectUser={async (user) => {
            try {
              await RejectContact(user.id);
              loadContacts();
              loadRequests();
            } catch (_) {
              setError("Error: Please try again.");
            }
          }}
          onAcceptUser={async (user) => {
            try {
              await AcceptContact(user.id);
              loadContacts();
              loadRequests();
            } catch (_) {
              setError("Error: Please try again.");
            }
          }}
        />
      </TabPanel>
      <Dialog
        open={deleteModalOpen}
        onClose={() => {
          setSelectedUser(null);
          setDeleteModalOpen(false);
        }}
        PaperProps={{
          style: {
            borderRadius: "8px",
            boxShadow: "0px 19px 40px rgba(0, 0, 0, 0.06)",
          },
        }}
      >
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
          padding={3}
        >
          <img src={DeleteContactIcon} alt="" />
          <Typography variant="h3" fontWeight={600} color="#060E5E">
            Are you sure you want to delete this contact?
          </Typography>
          <Typography variant="body2" color="#50578F">
            The contact will be deleted from your contact list.
          </Typography>
          <Grid item mt={4}>
            <Grid container spacing={2}>
              <Grid item>
                <Button
                  variant="stroke"
                  style={{
                    width: "123px",
                    height: "48px",
                  }}
                  onClick={() => {
                    setSelectedUser(null);
                    setDeleteModalOpen(false);
                  }}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="primary"
                  style={{
                    width: "123px",
                    height: "48px",
                  }}
                  onClick={async () => {
                    await DeleteContact(selectedUser.id);
                    setSelectedUser(null);
                    setDeleteModalOpen(false);
                    loadContacts();
                    setSuccess("Successfully deleted contact.");
                  }}
                >
                  Delete
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
}
