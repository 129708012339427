import { AlertColor } from "@mui/material";
import { createContext, useState } from "react";

type ContextInterface = {
  text: string | null;
  severity: string | null;
  isOpen: boolean;
  setSuccess: (text: string, timeout?: number) => void;
  setError: (text: string, timeout?: number) => void;
  setWarning: (text: string, timeout?: number) => void;
  setAlertOpen: (open: boolean) => void;
};

const initialState = {
  text: null,
  severity: null,
  isOpen: false,
};

const AlertContext = createContext<ContextInterface>({
  ...initialState,
  setSuccess: (text: string, timeout?: number) => {},
  setError: (text: string, timeout?: number) => {},
  setWarning: (text: string, timeout?: number) => {},
  setAlertOpen: (open: boolean) => {},
});

export const AlertProvider = ({ children }: { children: any }) => {
  const [text, setText] = useState("");
  const [severity, setSeverity] = useState("");
  const [isOpen, setOpen] = useState(false);

  const setAlert = (text: string, severity: AlertColor, timeout: number) => {
    setText(text);
    setSeverity(severity);
    setOpen(true);

    if (timeout !== -1) {
      setTimeout(() => {
        setText("");
        setSeverity("");
        setOpen(false);
      }, timeout);
    }
  };

  return (
    <AlertContext.Provider
      value={{
        text,
        severity,
        isOpen,
        setSuccess: (text: string, timeout?: number) => {
          if (!timeout) timeout = 5000;
          setAlert(text, "success", timeout);
        },
        setError: (text: string, timeout?: number) => {
          if (!timeout) timeout = 5000;
          setAlert(text, "error", timeout);
        },
        setWarning: (text: string, timeout?: number) => {
          if (!timeout) timeout = 5000;
          setAlert(text, "warning", timeout);
        },
        setAlertOpen: (open: boolean) => {
          setOpen(open);
        },
      }}
    >
      {children}
    </AlertContext.Provider>
  );
};

export default AlertContext;
