import { TableRow } from "@mui/material";
import { DragTypes } from "@shared/types";
import React from "react";
import { useDrag, useDrop } from "react-dnd";

type Props = {
  element: any;
  onDropComplete: (source: any, target: any) => void;
  onDoubleClick: (element: any) => void;
  children: JSX.Element[];
};

interface DropResult {
  element: any;
}

export default function DraggableTableRow(props: Props) {
  const { element, children, onDropComplete, onDoubleClick } = props;
  const isFolder = "parentId" in element;

  const [, drag] = useDrag(() => ({
    type: isFolder ? DragTypes.FOLDER : DragTypes.FILE,
    item: { element, isFolder },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult<DropResult>();
      if (!dropResult) {
        return;
      }

      onDropComplete(item.element, dropResult.element);
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }));

  const [, drop] = useDrop(() => ({
    accept: isFolder ? [DragTypes.FILE, DragTypes.FOLDER] : [],
    drop: () => ({ element, isFolder }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }));

  function attachRef(el: HTMLTableRowElement) {
    drag(el);
    drop(el);
  }

  return (
    <TableRow ref={attachRef} onDoubleClick={() => onDoubleClick(element)}>
      {children}
    </TableRow>
  );
}
