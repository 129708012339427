import React, { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import {
  Dialog,
  Grid,
  Typography,
  DialogTitle,
  Button,
  IconButton,
  CircularProgress,
} from "@mui/material";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { humanFileSize } from "@shared/util/Functions";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { UploadFile } from "@shared/api/django";
import useAlert from "@shared/util/hooks";
import { selectUser } from "@shared/redux/slices/authSlice";
import { useSelector } from "react-redux";
import InboxIcon from "@graphics/final/filled/inbox.svg";

interface FileUploadModalProps {
  open: boolean;
  onClose: () => void;
  onUploadSuccess: () => void;
}

export default function FileUploadModal(props: FileUploadModalProps) {
  const [files, setFiles] = useState<File[] | null>(null);
  const { setError } = useAlert();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const user = useSelector(selectUser);

  const handleChange = (fileList: any) => {
    const arr = [];

    for (let i = 0; i < fileList.length; i++) {
      let file = fileList[i] as File;

      arr.push(file);
    }

    setFiles(arr);
  };

  const removeFile = (name: string) => {
    if (files === null) return;

    setFiles(files.filter((obj) => obj.name !== name));
  };

  const handleUpload = async (files: File[]) => {
    setIsLoading(true);
    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      let response = await UploadFile(file, user.id).catch((e) => {
        setError("Unable to upload file(s), please try again.");
      });

      if (!response) break;
    }

    setIsLoading(false);
    setFiles(null);
    props.onClose();
    props.onUploadSuccess();
  };

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      PaperProps={{
        style: {
          borderRadius: "8px",
          boxShadow: "0px 19px 40px rgba(0, 0, 0, 0.06)",
        },
      }}
    >
      <DialogTitle
        style={{
          borderBottom: "1px solid #E6E7EF",
          padding: "16px 24px",
          background:
            "linear-gradient(107.39deg, #FFFFFF -0.77%, #EEF2FE 100%)",
        }}
      >
        <Grid container spacing={1}>
          <Grid item>
            <img src={InboxIcon} alt="" />
          </Grid>
          <Grid item>
            <Typography variant="h3" fontWeight={600} color="#060E5E">
              Upload Files
            </Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      <FileUploader
        handleChange={handleChange}
        name="file"
        multiple={true}
        fileOrFiles={files}
      >
        <Grid
          container
          flexDirection="column"
          alignItems="center"
          style={{
            padding: "20px",
            margin: "20px",
            border: "2px dashed #E6E7EF",
            borderRadius: "4px",
            width: "461px",
          }}
        >
          <Grid
            style={{
              opacity: 0.3,
            }}
          >
            <img src="./icons/illustrations/file_cluster.svg" alt="" />
          </Grid>
          <Typography variant="body2" sx={{ mt: 2 }}>
            <span style={{ textDecoration: "underline" }}>Click to upload</span>{" "}
            or drag and drop
          </Typography>
          <Typography sx={{ mt: 2 }} variant="body2" color="#8387AF">
            Max file size is 1 GB
          </Typography>
        </Grid>
      </FileUploader>
      {files && files.length > 0 && (
        <Grid container>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow
                  style={{
                    border: 0,
                    backgroundColor: "rgba(248, 248, 248, 1)",
                  }}
                  sx={{ "td, th": { border: 0 } }}
                >
                  <TableCell>Total Files: {files?.length}</TableCell>
                  <TableCell>
                    Total size:{" "}
                    {humanFileSize(
                      files.map((file) => file.size).reduce((a, b) => a + b, 0)
                    )}
                  </TableCell>
                  <TableCell
                    onClick={() => setFiles(null)}
                    style={{
                      cursor: "pointer",
                    }}
                  >
                    <span style={{ textDecoration: "underline" }}>
                      Clear All
                    </span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {files?.map((file) => (
                  <TableRow key={file.name} sx={{ "td, th": { border: 0 } }}>
                    <TableCell component="th" scope="row">
                      {file.name}
                    </TableCell>
                    <TableCell>{humanFileSize(file.size)}</TableCell>
                    <TableCell align="right">
                      <IconButton onClick={() => removeFile(file.name)}>
                        <HighlightOffIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      )}
      <Grid
        container
        alignItems="center"
        justifyContent="flex-end"
        style={{
          borderTop: "1px solid rgba(212, 212, 212, 0.8)",
          height: "80px",
          marginTop: "15px",
          paddingRight: "24px",
        }}
        spacing={2}
      >
        <Button
          variant="primary"
          style={{
            height: "48px",
            width: "144px",
          }}
          disabled={files === null || files.length === 0}
          onClick={() => {
            if (!files) return;

            handleUpload(files);
          }}
        >
          Upload Files
          {isLoading && (
            <CircularProgress style={{ marginLeft: "5px" }} size="20px" />
          )}
        </Button>
      </Grid>
    </Dialog>
  );
}
