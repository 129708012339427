import React from "react";

import { Grid, Avatar, IconButton } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ProfileDisplayMenu from "./profileDisplayMenu";
import Typography from "@mui/material/Typography";

interface ProfileDisplayProps {
  user: any;
  subtitle?: string;
}

export default function ProfileDisplay(props: ProfileDisplayProps) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const initials = props.user.firstName[0] + props.user.lastName[0];
  const fullName = props.user.firstName + " " + props.user.lastName;

  const openMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  return (
    <Grid container flexDirection="row" flexWrap="nowrap">
      <ProfileDisplayMenu
        user={props.user}
        open={open}
        handleClose={closeMenu}
        anchorEl={anchorEl}
      />
      <Grid container alignItems="center" justifyContent="flex-end" spacing={1}>
        <Grid item>
          {props.user.profileImageUrl !== null ? (
            <Avatar alt={fullName} src={props.user.profileImageUrl}></Avatar>
          ) : (
            <Avatar
              alt={fullName}
              style={{
                textTransform: "uppercase",
              }}
            >
              {initials}
            </Avatar>
          )}
        </Grid>
        <Grid item>
          <Typography variant="body2" fontWeight={600}>
            {fullName}
          </Typography>
        </Grid>
        <Grid item alignSelf="center">
          <IconButton
            onClick={openMenu}
            style={{
              padding: 0,
            }}
          >
            {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  );
}
