import { Grid, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import TabPanel from "@shared/components/TabPanel";
import OrganizationSettings from "./OrganizationSettings";
import ProfileSettings from "./ProfileSettings";
import SecuritySettings from "./SecuritySettings";

export default function Settings() {
  const [selectedTab, setSelectedTab] = useState<number>(0);

  return (
    <>
      <Grid container flexDirection="column">
        <Grid item sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={selectedTab}
            onChange={(_, newValue: number) => setSelectedTab(newValue)}
            style={{
              marginLeft: "2em",
            }}
          >
            <Tab label="Profile" />
            <Tab label="Security" />
            <Tab label="Organization" />
          </Tabs>
        </Grid>
        <TabPanel value={selectedTab} index={0}>
          <ProfileSettings />
        </TabPanel>
        <TabPanel value={selectedTab} index={1}>
          <SecuritySettings />
        </TabPanel>
        <TabPanel value={selectedTab} index={2}>
          <OrganizationSettings />
        </TabPanel>
      </Grid>
    </>
  );
}
