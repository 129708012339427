import {
  Button,
  Card,
  CardActionArea,
  Dialog,
  DialogTitle,
  Divider,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useSelector } from "react-redux";
import { selectUser } from "@shared/redux/slices/authSlice";
import { useState } from "react";
import ProfileDisplay from "./profileDisplay";
import { CreateContact } from "@shared/api";
import useAlert from "@shared/util/hooks";
import AddContactIcon from "@graphics/final/filled/plus-contact.svg";

export default function SearchBar() {
  const user = useSelector(selectUser);
  const { setSuccess } = useAlert();

  // State
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [addContactModalOpen, setAddContactModalOpen] =
    useState<boolean>(false);
  const [contactUser, setContactUser] = useState<string>("");

  return (
    <>
      <Grid
        container
        style={{
          borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        }}
        flexDirection="row"
        flexWrap="nowrap"
        alignItems="center"
      >
        <Grid
          item
          style={{
            padding: "16px 0",
            paddingLeft: "48px",
          }}
          md={6}
        >
          <SearchIcon />
          <TextField
            placeholder="Find a file: type a name or hashtag"
            variant="standard"
            style={{
              marginLeft: "10px",
              width: "80%",
            }}
            InputProps={{
              disableUnderline: true,
              style: {
                padding: 0,
              },
            }}
            value={searchTerm}
            onChange={(event) => setSearchTerm(event.target.value)}
          />
        </Grid>
        <Grid item md={4}>
          <Grid
            container
            justifyContent="flex-end"
            style={{
              height: "100%",
            }}
            spacing={4}
          >
            <Grid item>
              <Card
                style={{
                  height: "100%",
                  display: "flex",
                  border: "none",
                  boxShadow: "none",
                  backgroundColor: "transparent",
                }}
                onClick={() => setAddContactModalOpen(true)}
              >
                <CardActionArea>
                  <Grid container justifyContent="center" alignItems="center">
                    <img src={AddContactIcon} alt="" />
                    <Typography
                      variant="body2"
                      marginLeft="10px"
                      fontWeight={600}
                      color="#060E5E"
                    >
                      Add Contact
                    </Typography>
                  </Grid>
                </CardActionArea>
              </Card>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={0.1} pl={2}>
          <Divider
            orientation="vertical"
            style={{
              backgroundColor: "E6E7EF",
              width: "5px",
              height: "30px",
            }}
          />
        </Grid>
        <Grid item md={1.75}>
          <ProfileDisplay user={user} />
        </Grid>
      </Grid>
      <Dialog
        open={addContactModalOpen}
        onClose={() => {
          setContactUser("");
          setAddContactModalOpen(false);
        }}
        PaperProps={{
          style: {
            borderRadius: "8px",
            boxShadow: "0px 19px 40px rgba(0, 0, 0, 0.06)",
          },
        }}
      >
        <DialogTitle
          style={{
            borderBottom: "1px solid #E6E7EF",
            padding: "16px 24px",
            background:
              "linear-gradient(107.39deg, #FFFFFF -0.77%, #EEF2FE 100%)",
          }}
        >
          <Grid container spacing={1}>
            <Grid item>
              <img src={AddContactIcon} alt="" />
            </Grid>
            <Grid item>
              <Typography variant="h3" fontWeight={600} color="#060E5E">
                Add Contact
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <Grid container flexDirection="column" padding={3}>
          <Typography variant="body2" fontWeight={600} color="#060E5E">
            User
          </Typography>
          <Grid container mt={1}>
            <TextField
              label="Enter username or email"
              variant="outlined"
              size="small"
              value={contactUser}
              onChange={(event) => setContactUser(event.target.value)}
            />
          </Grid>
          <Divider
            style={{
              backgroundColor: "E6E7EF",
              width: "100%",
              marginTop: "1em",
            }}
          />
          <Grid container mt={2} justifyContent="flex-end">
            <Grid item>
              <Button
                variant="primary"
                style={{
                  height: "48px",
                  width: "144px",
                }}
                onClick={async () => {
                  await CreateContact(contactUser);
                  setContactUser("");
                  setAddContactModalOpen(false);
                  setSuccess("Successfully added contact.");
                }}
              >
                Send Request
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
}
