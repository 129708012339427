import React, { useState } from "react";
import {
  Chip,
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableContainer,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { withStyles } from "@mui/styles";
import FileAccessAvatarList from "./FileAccessAvatarList";
import { humanFileSize } from "@shared/util/Functions";
import { DateTime } from "luxon";
import FileOwnerAvatar from "./FileOwnerAvatar";
import TagIcon from "@mui/icons-material/Tag";
import { AddFileTag, RemoveFileTag } from "@shared/api/node";

interface FilePropertiesProps {
  file: any;
  open: boolean;
  onClose: () => void;
  reloadFile: () => void;
}

const TableCell = withStyles({
  root: {
    borderBottom: "none",
    paddingLeft: 0,
  },
})(MuiTableCell);

export default function FilePropertiesDialog(props: FilePropertiesProps) {
  const [hashtagInputFocused, setHashtagInputFocused] =
    useState<boolean>(false);
  const [hashtagValue, setHashtagValue] = useState<string>("");

  if (!props.file) return null;

  const handleHashtagKeydown = async (event: any) => {
    if (event.keyCode !== 13) return;

    if (hashtagValue === "") return;

    setHashtagValue("");
    await AddFileTag(props.file.id, hashtagValue);
    props.reloadFile();
  };

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle
        style={{
          borderBottom: "1px solid rgba(180, 180, 180, 0.8)",
        }}
      >
        <Grid container justifyContent="space-between">
          <span>Info: {props.file.name}</span>
          <IconButton onClick={() => props.onClose()}>
            <HighlightOffIcon />
          </IconButton>
        </Grid>
      </DialogTitle>

      <Grid container flexDirection="column" margin="1.5em">
        <Grid>
          <Typography fontSize="18px" fontWeight="450">
            Tags:
          </Typography>
          <TextField
            hiddenLabel
            size="small"
            style={{
              marginTop: "0.5em",
              width: "85%",
            }}
            variant="filled"
            placeholder="Add new tag"
            InputProps={{
              startAdornment: (
                <TagIcon
                  style={{ color: hashtagInputFocused ? "black" : "grey" }}
                />
              ),
            }}
            onClick={() => setHashtagInputFocused(true)}
            onBlur={() => setHashtagInputFocused(false)}
            onKeyDown={handleHashtagKeydown}
            value={hashtagValue}
            onChange={(event) => setHashtagValue(event.target.value)}
          />

          <Grid container marginTop="1em" alignItems="center">
            <Typography marginRight="0.5em">Tags:</Typography>
            {props.file.tags.map((fileTag: any) => {
              return (
                <Chip
                  label={fileTag.tag}
                  onDelete={async () => {
                    await RemoveFileTag(props.file.id, fileTag.id);
                    props.reloadFile();
                  }}
                  style={{
                    marginLeft: "5px",
                    borderRadius: 0,
                  }}
                  size="small"
                />
              );
            })}
          </Grid>
        </Grid>
        <Grid container marginTop="1em">
          <Typography fontSize="18px" fontWeight="450">
            System Properties:
          </Typography>
          <TableContainer>
            <Table size="small">
              <TableBody>
                <TableRow>
                  <TableCell>Who has access:</TableCell>
                  <TableCell>
                    <FileAccessAvatarList file={props.file} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>File Type:</TableCell>
                  <TableCell>{props.file.mimeType}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Size:</TableCell>
                  <TableCell>{humanFileSize(props.file.fileSize)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Date Uploaded:</TableCell>
                  <TableCell>
                    {DateTime.fromISO(props.file.createdAt).toLocaleString(
                      DateTime.DATE_SHORT
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Uploaded By:</TableCell>
                  <TableCell>
                    <FileOwnerAvatar showAvatar={false} file={props.file} />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Location</TableCell>
                  <TableCell>
                    <a
                      rel="noreferrer"
                      target="_blank"
                      href={"https://" + props.file.cid + ".ipfs.w3s.link/"}
                    >
                      IPFS
                    </a>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Dialog>
  );
}
