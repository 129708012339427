import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ConfirmEmail } from "@shared/api";
import { useAppDispatch } from "@shared/redux/hooks";
import { login } from "@shared/redux/slices/authSlice";
import { getErrorMessage } from "@shared/util/Functions";
import useAlert from "@shared/util/hooks";

export default function EmailConfirmation() {
  const { setError, setSuccess } = useAlert();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const token = searchParams.get("token");

    if (!token || token.length === 0) {
      setError("Error: Invalid token.");
      navigate("/");
      return;
    }

    async function confirm() {
      if (!token) {
        return;
      }

      const response = await ConfirmEmail(token).catch((error) => {
        setError(getErrorMessage(error), 5000);
        navigate("/");
      });

      if (response) {
        setSuccess("Email successfully confirmed.");
        dispatch(login({ user: response.data, status: "CONFIRMED" }));
        navigate("/");
      }
    }

    confirm();
  }, [dispatch, navigate, searchParams, setError, setSuccess]);

  return null;
}
