import React from "react";
import {
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Button,
  Typography,
} from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import UserAvatar from "../../UserAvatar";

interface Props {
  file: any;
  open: boolean;
  onClose: () => void;
  reloadFile: () => void;
}

export default function FileEditingDialog(props: Props) {
  if (!props.file) return null;

  return (
    <Dialog open={props.open} onClose={props.onClose}>
      <DialogTitle
        style={{
          borderBottom: "1px solid rgba(180, 180, 180, 0.8)",
        }}
      >
        <Grid container justifyContent="space-between">
          <Typography variant="h6">Edit: {props.file.name}</Typography>
          <IconButton onClick={() => props.onClose()}>
            <HighlightOffIcon />
          </IconButton>
        </Grid>
      </DialogTitle>

      <Grid container flexDirection="column" margin="1.5em">
        <Typography>Sharing Settings</Typography>
        <Grid container spacing={2} alignItems="center" mb={2}>
          <Grid item>
            <TextField
              hiddenLabel
              size="small"
              variant="filled"
              placeholder="Enter email or username"
            />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              style={{
                borderRadius: 100,
              }}
            >
              Send Invite
            </Button>
          </Grid>
        </Grid>
        <Grid container flexDirection="column">
          {props.file.users.map((fileUser: any) => {
            let user = fileUser.user;
            return (
              <Grid item>
                <Grid container alignItems="center">
                  <UserAvatar user={user}></UserAvatar>
                  <Typography ml={1}>
                    {user.firstName} {user.lastName}
                  </Typography>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </Dialog>
  );
}
