import React from "react";
import {
  Menu,
  MenuItem,
  Grid,
  Typography,
  Tooltip,
  Avatar,
} from "@mui/material";
import { useAppDispatch } from "@shared/redux/hooks";
import { LogoutUser } from "@shared/api/node";
import useAlert from "@shared/util/hooks";
import { logout, selectUser } from "@shared/redux/slices/authSlice";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { useSelector } from "react-redux";
import Divider from "@mui/material/Divider";
import SettingsIcon from "@graphics/final/outlined/setting-two.svg";
import LogoutIcon from "@graphics/final/outlined/logout.svg";

interface ProfileDisplayMenuProps {
  open: boolean;
  handleClose: () => void;
  anchorEl: any;
  user: any;
}

const CustomMenu = styled(Menu)({
  "& .MuiPaper-root": {
    backgroundColor: "#FFFFFF",
    border: "1px solid #E6E7EF",
    boxShadow: "0px 9px 10px rgba(0, 0, 0, 0.04)",
    borderRadius: "8px",
  },
});

export default function ProfileDisplayMenu(props: ProfileDisplayMenuProps) {
  const dispatch = useAppDispatch();
  const { setError, setSuccess } = useAlert();
  const navigate = useNavigate();
  const user = useSelector(selectUser);

  const handleLogout = async () => {
    await LogoutUser()
      .then(() => {
        dispatch(logout());
        setSuccess("Successfully logged out.", 5000);
      })
      .catch((error) => {
        setError(
          error?.response?.data?.message ?? "Error: Please try again.",
          5000
        );
      });
  };

  const entries = [
    {
      label: "Account Settings",
      icon: <img src={SettingsIcon} width="20px" height="20px" alt="" />,
      border: false,
      onClick: () => {
        navigate("/settings");
      },
      enabled: true,
    },
    {
      label: "Logout",
      icon: <img src={LogoutIcon} width="20px" height="20px" alt="" />,
      border: true,
      onClick: handleLogout,
      enabled: true,
    },
  ];

  return (
    <CustomMenu
      open={props.open}
      anchorEl={props.anchorEl}
      onClose={props.handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: -10,
        horizontal: "center",
      }}
      style={{}}
    >
      <Grid
        container
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        style={{
          marginRight: "2em",
        }}
      >
        {user.organization && (
          <>
            <MenuItem
              style={{
                width: "100%",
              }}
            >
              <Grid container spacing={1.5} alignItems="center">
                <Grid item>
                  <Avatar
                    src={user.organization.imageUrl}
                    sx={{ width: 24, height: 24 }}
                  />
                </Grid>
                <Grid item>
                  <Typography variant="body2" color="#060E5E">
                    {user.organization.name}
                  </Typography>
                </Grid>
              </Grid>
            </MenuItem>
            <Divider
              style={{
                backgroundColor: "E6E7EF",
                width: "100%",
              }}
            />
          </>
        )}
        {entries.map((entry) => {
          return (
            <Tooltip title={entry.enabled ? "" : "Coming soon"}>
              <MenuItem
                onClick={() => {
                  if (entry.enabled) {
                    entry.onClick();
                  }
                }}
                style={{
                  width: "100%",
                }}
              >
                <Grid container spacing={1.5} alignItems="center">
                  <Grid item>{entry.icon}</Grid>
                  <Grid item>
                    <Typography variant="body2">{entry.label}</Typography>
                  </Grid>
                </Grid>
              </MenuItem>
            </Tooltip>
          );
        })}
      </Grid>
    </CustomMenu>
  );
}
