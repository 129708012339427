import {
  Card,
  Grid,
  CardContent,
  Typography,
  Button,
  Avatar,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import useAlert from "@shared/util/hooks";

interface AvatarCardProps {
  title: string;
  userAvatarUrl?: string;
  onFileSelected: (avatar: File | undefined) => void;
}

export default function AvatarCard(props: AvatarCardProps) {
  const { title, userAvatarUrl, onFileSelected } = props;

  const { setError } = useAlert();

  const ref = useRef<HTMLInputElement>(null);

  const [file, setFile] = useState<File>();
  const [preview, setPreview] = useState<string>();

  useEffect(() => {
    if (!file) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(file);
    setPreview(objectUrl);
    onFileSelected(file);

    // Free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  useEffect(() => {
    if (userAvatarUrl) {
      setPreview(userAvatarUrl);
    }
  }, [userAvatarUrl]);

  return (
    <Card
      sx={{
        display: "flex",
        background: "linear-gradient(107.39deg, #FFFFFF -0.77%, #EEF2FE 100%)",
        marginTop: "1em",
        border: "1px solid #E6E7EF",
        borderRadius: "8px",
      }}
    >
      <Grid container alignItems="center">
        <Grid item>
          <CardContent sx={{ flex: "1 0 auto" }}>
            <Avatar src={preview} sx={{ width: "56px", height: "56px" }} />
          </CardContent>
        </Grid>
        <Grid item>
          <Grid container flexDirection="column">
            <Typography variant="h3" color="#060E5E">
              {title}
            </Typography>
            <Grid container spacing={2} alignItems="center" pt={0.5}>
              <Grid item>
                <Button variant="light" onClick={() => ref?.current?.click()}>
                  Upload now
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="text"
                  onClick={() => {
                    onFileSelected(undefined);
                    setPreview(undefined);
                  }}
                >
                  Remove
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <input
        id="avatar-input"
        name="avatar"
        type="file"
        ref={ref}
        style={{ display: "none" }}
        accept=".png, .jpg, .jpeg"
        onChange={(event) => {
          if (!event.target.files || event.target.files.length === 0) {
            setFile(undefined);
            return;
          }

          if (event.target.files[0].size >= 1000 * 1000 * 100) {
            setError("Files larger than 100MB are currently not supported.");
            setFile(undefined);
            return;
          }

          setFile(event.target.files[0]);
        }}
      />
    </Card>
  );
}
