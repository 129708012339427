import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import FileViewer from "@pages/FileViewer";
import ForgotPassword from "@pages/auth/ForgotPassword";
import Login from "@pages/auth/Login";
import { selectUser } from "@shared/redux/slices/authSlice";
import NavigationBar from "../components/navigation/navigationBar";
import { Grid } from "@mui/material";
import { FileView } from "@shared/types";
import Settings from "@pages/settings";
import ContactsPage from "@pages/contacts";
import Register from "@pages/auth/Register";
import CreateProfile from "@pages/auth/CreateProfile";
import useAlert from "@shared/util/hooks";
import EmailConfirmation from "@pages/auth/EmailConfirmation";
import ChangePassword from "@pages/auth/ChangePassword";
import VerifyMFA from "@pages/auth/VerifyMFA";
import SearchBar from "../components/searchBar";
import { AuthProvider } from "../providers";

export default function MainNavigation() {
  return (
    <Routes>
      <Route path="login" element={<Login />}></Route>
      <Route path="register" element={<Register />}></Route>
      <Route path="forgot-password" element={<ForgotPassword />}></Route>
      <Route path="change-password" element={<ChangePassword />}></Route>
      <Route path="create-profile" element={<CreateProfile />}></Route>
      <Route path="confirm-email" element={<EmailConfirmation />}></Route>
      <Route path="verify-mfa" element={<VerifyMFA />}></Route>
      <Route path="*" element={<SubNavigation />}></Route>
    </Routes>
  );
}

function SubNavigation() {
  const user = useSelector(selectUser);
  const { setWarning } = useAlert();

  useEffect(() => {
    if (user?.status === "PENDING_EMAIL_CONFIRMATION" && user.firstName) {
      setWarning(
        "Warning: You must verify your email before you can upload or download files.",
        -1
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  if (user && user.firstName === null) {
    return <Navigate to="/create-profile" />;
  }

  return (
    <AuthProvider>
      <Grid container flexWrap="nowrap">
        <NavigationBar />
        <Grid item flexGrow={1}>
          <Grid container flexDirection="column" width="100%">
            <SearchBar />
            <Routes>
              <Route index element={<FileViewer fileView={FileView.ALL} />} />
              <Route
                path="/folders/:folderId"
                element={<FileViewer fileView={FileView.ALL} />}
              />
              <Route
                path="/my-files"
                element={<FileViewer fileView={FileView.MY_FILES} />}
              />
              <Route
                path="/shared"
                element={<FileViewer fileView={FileView.SHARED} />}
              />
              <Route
                path="/favorites"
                element={<FileViewer fileView={FileView.FAVORITE} />}
              />
              <Route path="/contacts" element={<ContactsPage />} />
              <Route path="/settings" element={<Settings />} />
            </Routes>
          </Grid>
        </Grid>
      </Grid>
    </AuthProvider>
  );
}
