import { Grid } from "@mui/material";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export default function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Grid hidden={value !== index} id={`simple-tabpanel-${index}`} {...other}>
      {value === index && <Grid p={3}>{children}</Grid>}
    </Grid>
  );
}
