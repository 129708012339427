import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import NavigationItems from "./navigationItems";
import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Button,
  Grid,
  Tooltip,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUser } from "@shared/redux/slices/authSlice";
import FileUploadModal from "./fileUploadModal";
import Typography from "@mui/material/Typography";
import DarkLogo from "@graphics/logos/logo_colored_dark.svg";

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

export default function NavigationBar() {
  const user = useSelector(selectUser);
  const navigate = useNavigate();

  const pathname = window.location.pathname;
  const [fileModalOpen, setFileModalOpen] = useState<boolean>(false);

  return (
    user && (
      <>
        <Drawer open={true} variant="permanent">
          <Grid
            container
            flexDirection="column"
            style={{
              height: "100vh",
              backgroundImage: "url('/illustr.svg')",
              backgroundPosition: "center bottom",
              backgroundRepeat: "no-repeat",
              width: "280px",
            }}
          >
            <Toolbar
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                px: [1],
                marginBottom: "1em",
              }}
              style={{
                cursor: "pointer",
              }}
              onClick={() => navigate("/")}
            >
              <img src={DarkLogo} alt="OpenSigil Logo" width="167px" />
            </Toolbar>
            <Grid container justifyContent="center">
              <Button
                variant="primary"
                style={{
                  height: "48px",
                  width: "232px",
                }}
                onClick={() => setFileModalOpen(!fileModalOpen)}
              >
                Upload File
              </Button>
              <FileUploadModal
                open={fileModalOpen}
                onClose={() => setFileModalOpen(false)}
                onUploadSuccess={() => window.location.reload()}
              />
            </Grid>
            <List
              component="nav"
              style={{
                marginLeft: "10px",
                marginTop: "20px",
              }}
            >
              {NavigationItems.map((item, index) => (
                <NavItem
                  index={index}
                  display={item.display}
                  icon={item.icon}
                  selected={
                    (pathname === item.path || item.regex?.test(pathname)) ??
                    false
                  }
                  path={item.path}
                  enabled={item.enabled}
                />
              ))}
            </List>
          </Grid>
        </Drawer>
      </>
    )
  );
}

interface NavItemProps {
  index: number;
  display: string;
  icon: any;
  selected: boolean;
  path: string;
  enabled: boolean;
}

function NavItem(props: NavItemProps) {
  const navigate = useNavigate();

  return (
    <>
      <Grid container flexDirection="column" px={1}>
        <Tooltip title={props.enabled ? "" : "Coming soon"}>
          <ListItem
            key={props.index}
            disablePadding
            style={{
              borderRadius: "12px",
            }}
          >
            <ListItemButton
              onClick={() => {
                if (props.enabled) {
                  navigate(props.path);
                }
              }}
            >
              <ListItemIcon>{props.icon}</ListItemIcon>
              <ListItemText
                primary={
                  <Typography
                    variant="body2"
                    color={props.selected ? "#060E5E" : "#50578F"}
                    fontWeight={props.selected ? 600 : 400}
                  >
                    {props.display}
                  </Typography>
                }
                disableTypography
              />
            </ListItemButton>
          </ListItem>
        </Tooltip>
      </Grid>
    </>
  );
}
