import { TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";
import { FileTableHeadProps } from "@shared/types";
import { styled } from "@mui/material/styles";

const CustomTableHead = styled(TableHead)({
  "& th:first-child": {
    borderRadius: "12px",
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderCollapse: "seperate",
  },
  "& th:last-child": {
    borderRadius: "12px",
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderCollapse: "seperate",
  },
  th: {
    backgroundColor: "#f8f8f8",
    border: 0,
  },
});

export default function FileTableHead(props: FileTableHeadProps) {
  const { columns, onRequestSort, order, orderBy } = props;

  const createSortHandler =
    (property: any) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(property);
    };

  return (
    <CustomTableHead>
      <TableRow>
        {columns.map((column) => {
          return (
            <TableCell sortDirection={orderBy === column.id ? order : false}>
              <TableSortLabel
                active={orderBy === column.id}
                direction={orderBy === column.id ? order : "asc"}
                onClick={createSortHandler(column.id)}
              >
                {column.label}
              </TableSortLabel>
            </TableCell>
          );
        })}
      </TableRow>
    </CustomTableHead>
  );
}
