import DocumentFolderIcon from "@graphics/final/outlined/document-folder.svg";
import FolderOpenIcon from "@graphics/final/outlined/folder-open.svg";
import SharedWithMeIcon from "@graphics/final/outlined/people-safe-one.svg";
import FavoriteIcon from "@graphics/final/outlined/star.svg";
import ContactsIcon from "@graphics/final/outlined/id-card-h.svg";

const NavigationItems = [
  {
    display: "All Files",
    icon: <img src={DocumentFolderIcon} alt="" />,
    path: "/",
    regex: /\/folders\/\d+/,
    enabled: true,
  },
  {
    display: "My Files",
    icon: <img src={FolderOpenIcon} alt="" />,
    path: "/my-files",
    enabled: true,
  },
  {
    display: "Shared with me",
    icon: <img src={SharedWithMeIcon} alt="" />,
    path: "/shared",
    enabled: true,
  },
  {
    display: "Favorite",
    icon: <img src={FavoriteIcon} alt="" />,
    path: "/favorites",
    enabled: true,
  },
  {
    display: "Contacts",
    icon: <img src={ContactsIcon} alt="" />,
    path: "/contacts",
    enabled: true,
  },
];

export default NavigationItems;
