import React, { useState } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { VerifyMFA as VerifyMFAApi } from "@shared/api/node";
import useAlert from "@shared/util/hooks";
import { login } from "@shared/redux/slices/authSlice";
import { useAppDispatch } from "@shared/redux/hooks";
import { useNavigate } from "react-router-dom";
import { getErrorMessage } from "@shared/util/Functions";
import ReactCodeInput from "react-code-input";

export default function VerifyMFA() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { setError } = useAlert();

  // State
  const [token, setToken] = useState<string>("");

  const handleSubmit = async () => {
    let response = await VerifyMFAApi(token).catch((error) => {
      setError(getErrorMessage(error), 5000);
    });

    if (response) {
      dispatch(login({ user: response.data, status: "CONFIRMED" }));
      navigate("/");
    }

    setToken("");
  };

  return (
    <Grid
      container
      style={{
        width: "100vw",
        height: "100vh",
      }}
      justifyContent="center"
    >
      <Grid item md={6} sx={{ display: { xs: "none", md: "block" } }}>
        <Grid
          container
          height="100%"
          style={{
            backgroundImage: "url(/login_graphic.png)",
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
          justifyContent="center"
          alignItems="center"
        >
          <img
            src="logo_colored_dark.svg"
            alt="OpenSigil Logo"
            width="250px"
            style={{
              paddingBottom: "10em",
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={10} md={6}>
        <Grid
          container
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          style={{
            width: "100%",
            height: "100%",
          }}
          spacing={4}
        >
          <Grid item md={8} lg={6}>
            <Grid container textAlign="center" flexDirection="column">
              <Typography variant="h1" mt={4} color="#060E5E">
                2-step Verification
              </Typography>
              <Typography variant="body2" mt={2} color="#50578F">
                Enter the verification code provided by your authenticator app.
              </Typography>
            </Grid>
            <Grid container mt={2} justifyContent="center">
              <ReactCodeInput
                name="token"
                type="number"
                fields={6}
                inputMode="numeric"
                onChange={(value) => setToken(value)}
              />
              <Button
                type="submit"
                fullWidth
                variant="primary"
                sx={{ mt: 3, mb: 2 }}
                style={{
                  height: "40px",
                }}
                onClick={handleSubmit}
              >
                Verify
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
