import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import Navigation from "@shared/navigation";
import { ThemeProvider } from "@mui/material";
import theme from "@shared/vendors/mui";
import { AlertProvider } from "./shared/util/AlertContext";
import AlertPopup from "@shared/components/alertPopup";
import { Provider } from "react-redux";
import { persistor, store } from "@shared/redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { BrowserRouter } from "react-router-dom";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <AlertProvider>
            <AlertPopup />
            <Navigation />
          </AlertProvider>
        </BrowserRouter>
      </ThemeProvider>
    </PersistGate>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
