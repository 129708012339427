import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { LoginUser } from "@shared/api/node";
import useAlert from "@shared/util/hooks";
import { login } from "@shared/redux/slices/authSlice";
import { useAppDispatch } from "@shared/redux/hooks";
import { Link, useNavigate } from "react-router-dom";
import { getErrorMessage } from "@shared/util/Functions";

export default function Login() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { setError } = useAlert();

  // State
  const [identifier, setIdentifier] = useState<string>("");
  const [password, setPassword] = useState<string>("");

  const handleSubmit = async () => {
    let response = await LoginUser({
      identifier: identifier,
      password: password,
    }).catch((error) => {
      setError(getErrorMessage(error), 5000);
    });

    if (response) {
      dispatch(login(response.data));
      navigate("/");
    }
  };

  return (
    <Grid
      container
      style={{
        width: "100vw",
        height: "100vh",
      }}
      justifyContent="center"
    >
      <Grid item md={6} sx={{ display: { xs: "none", md: "block" } }}>
        <Grid
          container
          height="100%"
          style={{
            backgroundImage: "url(/login_graphic.png)",
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
          justifyContent="center"
          alignItems="center"
        >
          <img
            src="logo_colored_dark.svg"
            alt="OpenSigil Logo"
            width="250px"
            style={{
              paddingBottom: "10em",
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={10} md={6}>
        <Grid
          container
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          style={{
            width: "100%",
            height: "100%",
          }}
          spacing={4}
        >
          <Grid item md={8} lg={6}>
            <Grid container textAlign="center" flexDirection="column">
              <Typography variant="h1" mt={4} color="#060E5E">
                Sign in
              </Typography>
              <Typography variant="body2" mt={2} color="#50578F">
                Log in to your account to get started.
                <br /> Don't have an account?{" "}
                <Link to="/register">Sign up now</Link>
              </Typography>
            </Grid>
            <Grid container mt={1}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                placeholder="Email / Username"
                autoComplete="email"
                autoFocus
                value={identifier}
                onChange={(event) => setIdentifier(event.target.value)}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                placeholder="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
              />
              <Grid item width="100%">
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <FormControlLabel
                      control={<Checkbox value="remember" color="primary" />}
                      label="Remember me"
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      variant="text"
                      style={{
                        width: "100%",
                        height: "20px",
                      }}
                      onClick={() => navigate("/forgot-password")}
                    >
                      Forgot Password?
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="primary"
                sx={{ mt: 3, mb: 2 }}
                style={{
                  height: "40px",
                }}
                onClick={handleSubmit}
              >
                Sign In
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
