import React from "react";
import { Grid } from "@mui/material";
import FileTable from "@shared/components/table/FileTable";
import { FileView } from "@shared/types";
import { useParams } from "react-router-dom";

interface Props {
  fileView: FileView;
}

export default function FileViewer(props: Props) {
  const { folderId } = useParams();

  return (
    <>
      <Grid container flexDirection="column">
        <Grid item flexGrow={1}>
          <Grid
            container
            padding={3}
            style={{
              width: "100%",
            }}
          >
            <FileTable fileView={props.fileView} folderId={folderId} />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
