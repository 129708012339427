import React from "react";
import { Grid, Typography } from "@mui/material";
import NoFilesIcon from "@graphics/illustrations/no_files.svg";

export default function NoFiles() {
  return (
    <Grid container justifyContent={"center"} alignItems="center" height="50vh">
      <Grid item>
        <Grid
          container
          justifyContent={"center"}
          alignItems="center"
          flexDirection="column"
        >
          <Grid item>
            <img src={NoFilesIcon} alt="" />
          </Grid>
          <Grid item>
            <Typography variant="h1" color="#060E5E">
              Nothing uploaded yet :(
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
