import React from "react";
import {
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Grid,
  Typography,
  IconButton,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import UserAvatar from "@shared/components/UserAvatar";
import NoContacts from "./noContacts";
import DeleteIcon from "@graphics/final/outlined/delete.svg";

const CustomTableHead = styled(TableHead)({
  "& th:first-child": {
    borderRadius: "12px",
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderCollapse: "seperate",
  },
  "& th:last-child": {
    borderRadius: "12px",
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderCollapse: "seperate",
  },
  th: {
    backgroundColor: "#f8f8f8",
    border: 0,
  },
});

interface Props {
  contacts: any[] | null;
  onDeleteUser: (user: any) => void;
}

export default function ContactList(props: Props) {
  const { contacts, onDeleteUser } = props;

  const COLUMNS = [
    {
      label: (
        <Typography variant="h4" style={{ color: "#060E5E" }}>
          NAME
        </Typography>
      ),
      renderCell: (item: any) => (
        <Grid container alignItems="center">
          <UserAvatar user={item} size="MEDIUM" />
          <Grid item ml={1}>
            <Typography variant="body2" fontWeight={600} color="#060E5E">
              {item.firstName + " " + item.lastName}
            </Typography>
          </Grid>
        </Grid>
      ),
    },
    {
      label: (
        <Typography variant="h4" style={{ color: "#060E5E" }}>
          USERNAME
        </Typography>
      ),
      renderCell: (item: any) => (
        <Typography variant="body2" color="#50578F">
          {"@" + item.username}
        </Typography>
      ),
    },
    {
      label: (
        <Typography variant="h4" style={{ color: "#060E5E" }}>
          EMAIL
        </Typography>
      ),
      renderCell: (item: any) => (
        <Typography variant="body2" color="#50578F">
          {item.email}
        </Typography>
      ),
    },
    {
      label: (
        <Typography variant="h4" style={{ color: "#060E5E" }}>
          ORGANIZATION
        </Typography>
      ),
      renderCell: (item: any) => (
        <Typography variant="body2" color="#50578F">
          {item?.organization?.name}
        </Typography>
      ),
    },
    {
      label: null,
      renderCell: (item: any) => {
        return (
          <Grid container justifyContent="flex-end">
            <IconButton
              onClick={() => {
                onDeleteUser(item);
              }}
            >
              <img src={DeleteIcon} alt="" />
            </IconButton>
          </Grid>
        );
      },
    },
  ];

  if (!contacts || contacts.length === 0) {
    return <NoContacts />;
  }

  return (
    <>
      <TableContainer>
        <Table>
          <CustomTableHead>
            <TableRow>
              {COLUMNS.map((column) => {
                return <TableCell>{column.label}</TableCell>;
              })}
            </TableRow>
          </CustomTableHead>
          <TableBody>
            {contacts?.map((contact: any) => {
              return (
                <TableRow>
                  {COLUMNS.map((column) => {
                    return <TableCell>{column.renderCell(contact)}</TableCell>;
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
