// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
export function stableSort<T>(
  array: any[] | null,
  sortOrder: "asc" | "desc",
  comparator: (a: T, b: T) => number
) {
  if (!array) {
    return [];
  }

  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      console.log(order, sortOrder);
      if (sortOrder === "asc") {
        return order;
      } else {
        return -order;
      }
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
