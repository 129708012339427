import React from "react";
import {
  Dialog,
  DialogTitle,
  Grid,
  Typography,
  Divider,
  Button,
} from "@mui/material";
import ReactCodeInput from "react-code-input";
import ProtectIcon from "@graphics/final/filled/protect.svg";

interface Props {
  open: boolean;
  onCancel: () => void;
  onContinue: () => void;
  setToken: (token: string) => void;
}

export default function MFARemoveModal(props: Props) {
  const { open, onCancel, onContinue, setToken } = props;

  return (
    <>
      <Dialog
        open={open}
        onClose={() => onCancel()}
        PaperProps={{
          style: {
            borderRadius: "8px",
            boxShadow: "0px 19px 40px rgba(0, 0, 0, 0.06)",
          },
        }}
      >
        <DialogTitle
          style={{
            borderBottom: "1px solid #E6E7EF",
            padding: "16px 24px",
            background:
              "linear-gradient(107.39deg, #FFFFFF -0.77%, #EEF2FE 100%)",
          }}
        >
          <Grid container spacing={1}>
            <Grid item>
              <img src={ProtectIcon} alt="" />
            </Grid>
            <Grid item>
              <Typography variant="h3" fontWeight={600} color="#060E5E">
                2FA Removal
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <Grid container flexDirection="column" padding={3}>
          <Typography variant="body1" color="#50578F">
            Your account will no longer be protected by 2-factor authentication.
          </Typography>
          <Grid container mt={2}>
            <Typography variant="body2" fontWeight={600} color="#060E5E">
              Verification Code
            </Typography>
          </Grid>
          <Grid container mt={1}>
            <ReactCodeInput
              name="token"
              type="number"
              fields={6}
              inputMode="numeric"
              onChange={(value) => setToken(value)}
            />
          </Grid>
          <Divider
            style={{
              backgroundColor: "E6E7EF",
              width: "100%",
              marginTop: "1em",
            }}
          />
          <Grid container mt={2} justifyContent="space-around" spacing={2}>
            <Grid item>
              <Button
                variant="stroke"
                style={{
                  height: "48px",
                  width: "144px",
                }}
                onClick={() => onCancel()}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="primary"
                style={{
                  height: "48px",
                  width: "144px",
                }}
                onClick={() => onContinue()}
              >
                Remove
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
}
