import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Typography,
  Grid,
  Chip,
  IconButton,
  TablePagination,
} from "@mui/material";
import { CreateFolder, GetFile, GetFiles, MoveFile } from "@shared/api/node";

import {
  getErrorMessage,
  getFileTypeIcon,
  humanFileSize,
} from "@shared/util/Functions";
import { DateTime } from "luxon";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import FileSelectedMenu from "./components/FileSelectedMenu";
import FileAccessAvatarList from "./components/FileAccessAvatarList";
import FileOwnerAvatar from "./components/FileOwnerAvatar";
import { TableContainer, Table, TableBody, TableCell } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import CreateFolderModal from "../modals/CreateFolderModal";
import useAlert from "@shared/util/hooks";
import NoFiles from "./noFiles";
import DraggableTableRow from "./DraggableTableRow";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useNavigate } from "react-router-dom";
import PlusIcon from "@graphics/final/outlined/plus.svg";
import { FileColumn, FileTableProps, FileView } from "@shared/types";
import FileTableHead from "./tableHead";
import { stableSort } from "./sorting";

export default function FileTable(props: FileTableProps) {
  const { fileView, folderId = undefined } = props;

  const { setError, setSuccess } = useAlert();
  const navigate = useNavigate();

  const [files, setFiles] = useState<any[] | null>(null);
  const [fileSelected, setFileSelected] = useState<any>(null);
  const [fileMenuOpen, setFileMenuOpen] = useState<boolean>(false);
  const [fileAnchorEl, setFileAnchorEl] = useState<null | HTMLElement>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Create Folder Modal
  const [createFolderOpen, setCreateFolderOpen] = useState<boolean>(false);
  const [folderName, setFolderName] = useState<string>("");

  // Sorting state
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState<any>("calories");

  // Pagination state
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSort = (property: any) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const loadFiles = useCallback(async () => {
    setIsLoading(true);
    let response = await GetFiles(fileView, folderId);

    setFiles(response.data ?? []);
    setIsLoading(false);
  }, [fileView, folderId]);

  useEffect(() => {
    loadFiles();
  }, [loadFiles, fileView]);

  const onDropComplete = async (source: any, target: any) => {
    const sourceIsFolder = "parentId" in source;
    const sourceId = source.id;
    const targetId = target.id;

    try {
      await MoveFile(sourceIsFolder, sourceId, targetId);
      loadFiles();
      setSuccess(`Successfully moved ${sourceIsFolder ? "folder" : "file"}.`);
    } catch (_) {
      setError(`Error: Unable to move ${sourceIsFolder ? "folder" : "file"}.`);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const COLUMNS: FileColumn[] = [
    {
      id: 1,
      label: (
        <Typography variant="h4" style={{ color: "#060E5E" }}>
          FILE NAME
        </Typography>
      ),
      renderCell: (item: any) => (
        <Grid container alignItems="center" spacing={2}>
          <Grid item>{getFileTypeIcon(item.mimeType)}</Grid>
          <Grid item>
            <Typography variant="body1" fontWeight={600}>
              {item.name}
            </Typography>
          </Grid>
        </Grid>
      ),
      sortFn: (a: any, b: any) => {
        return a.name > b.name ? 1 : -1;
      },
    },
    {
      id: 2,
      label: (
        <Typography variant="h4" style={{ color: "#060E5E" }}>
          SIZE
        </Typography>
      ),
      renderCell: (item: any) => (
        <Typography>
          {item.fileSize ? humanFileSize(item.fileSize) : "N/A"}
        </Typography>
      ),
      sortFn(a, b) {
        return a.fileSize > b.fileSize ? 1 : -1;
      },
    },
    {
      id: 3,
      label: (
        <Typography variant="h4" style={{ color: "#060E5E" }}>
          DATE UPLOADED
        </Typography>
      ),
      renderCell: (item: any) => (
        <Typography>
          {DateTime.fromISO(item.createdAt).toLocaleString(DateTime.DATE_SHORT)}
        </Typography>
      ),
      sortFn(a, b) {
        return DateTime.fromISO(a.createdAt) > DateTime.fromISO(b.createdAt)
          ? 1
          : -1;
      },
    },
    {
      id: 4,
      label: (
        <Typography variant="h4" style={{ color: "#060E5E" }}>
          OWNER
        </Typography>
      ),
      renderCell: (file: any) => {
        return file.users && <FileOwnerAvatar showAvatar={true} file={file} />;
      },
      sortFn(a, b) {
        return 1;
      },
    },
    {
      id: 5,
      label: (
        <Typography variant="h4" style={{ color: "#060E5E" }}>
          WHO HAS ACCESS
        </Typography>
      ),
      renderCell: (file: any) => {
        return file.users && <FileAccessAvatarList file={file} />;
      },
      sortFn(a, b) {
        return 1;
      },
    },
    {
      id: 6,
      label: null,
      renderCell: (item: any) => {
        return (
          <Grid container justifyContent="flex-end">
            <IconButton
              onClick={(event) => {
                setFileSelected(item);
                setFileMenuOpen(true);
                setFileAnchorEl(event.currentTarget);
              }}
            >
              <MoreVertIcon />
            </IconButton>
          </Grid>
        );
      },
    },
  ];

  const visibleFiles = useMemo(() => {
    const comparator = COLUMNS.find((column) => column.id === orderBy)?.sortFn;
    if (!comparator) {
      return files;
    }

    console.log(files);

    return stableSort(files, order, comparator).slice(
      page * rowsPerPage,
      page * rowsPerPage + rowsPerPage
    );
  }, [COLUMNS, files, order, orderBy, page, rowsPerPage]);

  if (isLoading) {
    return (
      <Grid>
        <Grid container justifyContent={"center"} alignItems="center">
          <CircularProgress style={{ marginLeft: "5px" }} size="20px" />
        </Grid>
      </Grid>
    );
  }

  if (files && files.length === 0) {
    return <NoFiles />;
  }

  return (
    files && (
      <Grid item width={"100%"}>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Grid container>
              <Typography
                variant="h2"
                style={{
                  color: "#060E5E",
                  marginBottom: "1.25em",
                  marginRight: "0.5em",
                }}
              >
                {fileView === FileView.ALL ? "All Files" : ""}
                {fileView === FileView.MY_FILES ? "My Files" : ""}
                {fileView === FileView.SHARED ? "Shared With Me" : ""}
                {fileView === FileView.FAVORITE ? "Favorites" : ""}
              </Typography>
              <Chip
                label={files.length}
                sx={{
                  "& .MuiChip-label": {
                    fontWeight: "bold",
                  },
                }}
              />
            </Grid>
          </Grid>
          <Grid item>
            <IconButton onClick={() => setCreateFolderOpen(true)}>
              <img src={PlusIcon} width="24px" height="24px" alt="" />
              <Typography color="#060E5E" fontWeight={600}>
                Create Folder
              </Typography>
            </IconButton>
          </Grid>
        </Grid>
        <TableContainer>
          <Table>
            <FileTableHead
              columns={COLUMNS}
              onRequestSort={handleRequestSort}
              order={order}
              orderBy={orderBy}
            />
            <DndProvider backend={HTML5Backend}>
              <TableBody>
                {visibleFiles?.map((file: any) => {
                  return (
                    <DraggableTableRow
                      element={file}
                      onDropComplete={onDropComplete}
                      onDoubleClick={(element) => {
                        const isFolder = "parentId" in element;

                        if (isFolder) {
                          navigate(`/folders/${element.id}`);
                        }
                      }}
                    >
                      {COLUMNS.map((column) => {
                        return <TableCell>{column.renderCell(file)}</TableCell>;
                      })}
                    </DraggableTableRow>
                  );
                })}
              </TableBody>
            </DndProvider>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={files.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        <FileSelectedMenu
          anchorEl={fileAnchorEl}
          onClose={() => {
            setFileMenuOpen(false);
            setFileAnchorEl(null);
          }}
          file={fileSelected}
          open={fileMenuOpen}
          reloadFile={async () => {
            let response = await GetFile(fileSelected.id);

            setFileSelected(response.data);
          }}
          reloadAllFiles={async () => {
            let response = await GetFiles(fileView);

            setFiles(response.data ?? []);
          }}
        />
        <CreateFolderModal
          open={createFolderOpen}
          folderName={folderName}
          onCancel={() => setCreateFolderOpen(false)}
          onContinue={async () => {
            const response = await CreateFolder(folderName).catch((error) => {
              setError(getErrorMessage(error), 5000);
            });

            if (response) {
              setSuccess("Successfully created folder.");
              let response = await GetFiles(fileView);

              setFiles(response.data ?? []);
            }

            setCreateFolderOpen(false);
          }}
          setFolderName={(folderName) => setFolderName(folderName)}
        />
      </Grid>
    )
  );
}
