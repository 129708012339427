import { Alert, AlertColor, Collapse, Grid, IconButton } from "@mui/material";
import useAlert from "@shared/util/hooks";
import CloseIcon from "@mui/icons-material/Close";

const AlertPopup = () => {
  const { text, severity, isOpen, setAlertOpen } = useAlert();

  if (text && severity) {
    return (
      <Collapse in={isOpen}>
        <Grid container justifyContent="center">
          <Alert
            severity={severity as AlertColor}
            style={{
              position: "absolute",
              zIndex: 1340,
              textAlign: "center",
              width: "25%",
              top: 10,
              borderRadius: "100px",
            }}
            action={
              <IconButton
                color="inherit"
                onClick={() => {
                  setAlertOpen(false);
                }}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
          >
            {text}
          </Alert>
        </Grid>
      </Collapse>
    );
  } else {
    return null;
  }
};

export default AlertPopup;
