import {
  Button,
  Card,
  CardContent,
  Grid,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SetupMFA, UpdatePassword, ConfirmMFA, RemoveMFA } from "@shared/api";
import MFASetupModal from "@shared/components/modals/MFASetupModal";
import { login, selectUser } from "@shared/redux/slices/authSlice";
import { getErrorMessage } from "@shared/util/Functions";
import useAlert from "@shared/util/hooks";
import qrcode from "qrcode";
import MFAVerifyModal from "@shared/components/modals/MFAVerifyModal";
import MFARemoveModal from "@shared/components/modals/MFARemoveModal";
import { useAppDispatch } from "@shared/redux/hooks";
import PhoneIcon from "@graphics/final/outlined/phone.svg";

export default function SecuritySettings() {
  const { setError, setSuccess } = useAlert();
  const dispatch = useAppDispatch();

  const user = useSelector(selectUser);

  // User Password Info
  const [currentPassword, setCurrentPassword] = useState<string>("");
  const [newPassword, setNewPassword] = useState<string>("");
  const [newPasswordConfirmed, setNewPasswordConfirmed] = useState<string>("");
  const [hasPasswordError, setHasPasswordError] = useState<boolean>(false);

  // User 2-fa Info
  const [hasSetupMfa, setHasSetupMfa] = useState<boolean>(false);
  const [mfaState, setMfaState] = useState<
    "SETUP" | "PENDING_CONFIRMATION" | "CONFIRMED" | "PENDING_REMOVAL" | null
  >(null);
  const [otpUrl, setOtpUrl] = useState<string>("");
  const [token, setToken] = useState<string>("");

  const changePassword = async () => {
    if (
      currentPassword.length === 0 ||
      newPassword.length === 0 ||
      newPasswordConfirmed.length === 0
    ) {
      setError("Error: Form incomplete.");
      setHasPasswordError(true);
      return;
    }

    if (newPassword !== newPasswordConfirmed) {
      setError("Error: New passwords don't match.");
      setHasPasswordError(true);
      return;
    }

    const response = await UpdatePassword(currentPassword, newPassword).catch(
      (error) => {
        setError(getErrorMessage(error), 5000);
      }
    );

    if (response) {
      setSuccess("Password successfully updated!");
      setCurrentPassword("");
      setNewPassword("");
      setNewPasswordConfirmed("");
    }
  };

  useEffect(() => {
    if (user) {
      setHasSetupMfa(user.isMfaConfirmed);
    }
  }, [user]);

  return (
    <Grid container flexDirection="column" width="40%">
      <Typography variant="h2" color="#060E5E">
        Password Settings
      </Typography>
      <Grid item mt={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container flexDirection="column">
              <Typography variant="body3">Current Password</Typography>
              <TextField
                required
                type="password"
                placeholder="Current Password"
                value={currentPassword}
                onChange={(event) => {
                  setHasPasswordError(false);
                  setCurrentPassword(event.target.value);
                }}
                error={hasPasswordError && currentPassword.length === 0}
              />
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container flexDirection="column">
              <Typography variant="body3" fontWeight={600} color="#060E5E">
                New Password
              </Typography>
              <TextField
                required
                type="password"
                placeholder="New Password"
                value={newPassword}
                onChange={(event) => {
                  setHasPasswordError(false);
                  setNewPassword(event.target.value);
                }}
                error={
                  hasPasswordError &&
                  (newPassword.length === 0 ||
                    newPassword !== newPasswordConfirmed)
                }
              />
            </Grid>
          </Grid>
          <Grid item xs={6}>
            <Grid container flexDirection="column">
              <Typography variant="body3">&nbsp;</Typography>
              <TextField
                required
                type="password"
                placeholder="Repeat new password"
                value={newPasswordConfirmed}
                onChange={(event) => {
                  setHasPasswordError(false);
                  setNewPasswordConfirmed(event.target.value);
                }}
                error={
                  hasPasswordError &&
                  (newPasswordConfirmed.length === 0 ||
                    newPassword !== newPasswordConfirmed)
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={2} mt={2}>
        <Grid item>
          <Button
            onClick={() => changePassword()}
            variant="primary"
            style={{
              width: "176px",
              height: "48px",
            }}
          >
            Save
          </Button>
        </Grid>
      </Grid>
      <Typography variant="h2" color="#060E5E" mt={4}>
        2FA Verification
      </Typography>
      <Grid item>
        <Grid container>
          <Card
            style={{
              height: "80px",
              display: "flex",
              border: "1px solid #E6E7EF",
              borderRadius: "8px",
              boxShadow: "none",
              backgroundColor: "transparent",
              marginTop: "1em",
            }}
          >
            <CardContent
              style={{
                padding: 0,
              }}
            >
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                style={{
                  padding: "8px 16px",
                  height: "100%",
                  width: "100%",
                }}
              >
                <img src={PhoneIcon} alt="" />
                <Grid item ml={1}>
                  <Typography variant="body2" fontWeight={600} color="#060E5E">
                    Authenticator App 2-Step Verification
                  </Typography>
                  <Typography variant="body2" color="#8387AF">
                    Secure your account with a software token
                  </Typography>
                </Grid>
                <Switch
                  checked={hasSetupMfa}
                  onChange={async (event) => {
                    event.stopPropagation();
                    const value = event.target.checked;

                    if (value) {
                      const response = await SetupMFA();
                      const dataURL = await qrcode.toDataURL(
                        response.data.otpURL
                      );

                      setMfaState("SETUP");
                      setOtpUrl(dataURL);
                    } else {
                      setMfaState("PENDING_REMOVAL");
                    }
                  }}
                />
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <MFASetupModal
        open={mfaState === "SETUP"}
        onCancel={() => {
          setMfaState(null);
          setHasSetupMfa(false);
          setOtpUrl("");
        }}
        onContinue={() => {
          // Prevent dialogs glitching in/out when going to the next step
          setMfaState(null);
          setTimeout(() => {
            setMfaState("PENDING_CONFIRMATION");
            setOtpUrl("");
          }, 150);
        }}
        otpUrl={otpUrl}
      />
      <MFAVerifyModal
        open={mfaState === "PENDING_CONFIRMATION"}
        onCancel={() => {
          setMfaState(null);
          setHasSetupMfa(false);
        }}
        onContinue={async () => {
          const response = await ConfirmMFA(token).catch((error) => {
            setError(getErrorMessage(error), 5000);
          });

          if (response) {
            setMfaState("CONFIRMED");
            setToken("");

            setSuccess("Successfully enabled 2-factor authentication!");
            dispatch(login({ user: response.data, status: "CONFIRMED" }));
          }
        }}
        setToken={(token) => setToken(token)}
      />
      <MFARemoveModal
        open={mfaState === "PENDING_REMOVAL"}
        onCancel={() => {
          setMfaState(null);
        }}
        onContinue={async () => {
          const response = await RemoveMFA(token).catch((error) => {
            setError(getErrorMessage(error), 5000);
          });

          if (response) {
            setMfaState(null);
            setToken("");

            setSuccess("Successfully removed 2-factor authentication.");
            setHasSetupMfa(false);
            dispatch(login({ user: response.data, status: "CONFIRMED" }));
          }
        }}
        setToken={(token) => setToken(token)}
      />
    </Grid>
  );
}
