import { Avatar, Grid, Typography } from "@mui/material";
import { useAppSelector } from "@shared/redux/hooks";
import { selectUser } from "@shared/redux/slices/authSlice";

interface FileOwnerAvatarProps {
  file: any;
  showAvatar: boolean;
}

export default function FileOwnerAvatar(props: FileOwnerAvatarProps) {
  const user = useAppSelector(selectUser);

  const ownerRelationship = props.file.users.find(
    (user: any) => user.permission === "OWNER"
  );

  if (!ownerRelationship) {
    return null;
  }

  const owner = ownerRelationship.user;

  const profileImageUrl = owner.profileImageUrl;
  const fullName = owner.firstName + " " + owner.lastName;
  const initials = owner.firstName[0] + owner.lastName[0];

  return (
    <Grid container alignItems="center">
      {!props.showAvatar ? null : profileImageUrl != null ? (
        <Avatar alt="" src={profileImageUrl} />
      ) : (
        <Avatar
          style={{
            textTransform: "uppercase",
          }}
        >
          {initials}
        </Avatar>
      )}
      <Typography
        style={{
          marginLeft: props.showAvatar ? "1em" : "",
        }}
      >
        {owner.id === user.id ? "Me" : fullName}
      </Typography>
    </Grid>
  );
}
