const FILE_TYPE_MAP: any = {
  "audio.svg": [
    "audio/aac",
    "application/x-cdf",
    "audio/midi",
    "audio/x-midi",
    "audio/mpeg",
    "audio/ogg",
    "audio/opus",
    "audio/wav",
    "audio/webm",
    "audio/3gpp",
    "audio/3gpp2",
  ],
  "doc.svg": [
    "application/x-abiword",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ],
  "folder.svg": [],
  "img.svg": [
    "image/avif",
    "image/bmp",
    "image/gif",
    "image/vnd.microsoft.icon",
    "image/jpeg",
    "image/png",
    "image/svg+xml",
    "image/tiff",
    "image/webp",
  ],
  "pdf.svg": ["application/pdf"],
  "presentation.svg": [
    "application/vnd.oasis.opendocument.presentation",
    "application/vnd.ms-powerpoint",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  ],
  "video.svg": [
    "video/x-msvideo",
    "video/mp4",
    "video/mpeg",
    "video/ogg",
    "video/mp2t",
    "video/webm",
    "video/3gpp",
    "video/3gpp2",
  ],
  "zip.svg": [
    "application/x-freearc",
    "application/x-bzip",
    "application/x-bzip2",
    "application/gzip",
    "application/vnd.rar",
    "application/x-tar",
    "application/zip",
    "application/x-7z-compressed",
  ],
};

export function humanFileSize(bytes: number, si = false, dp = 1) {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + " B";
  }

  const units = si
    ? ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]
    : ["KiB", "MiB", "GiB", "TiB", "PiB", "EiB", "ZiB", "YiB"];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return bytes.toFixed(dp) + " " + units[u];
}

export function getFileTypeIcon(mimeType: string) {
  let keys = Object.keys(FILE_TYPE_MAP);
  let imagePath = "folder.svg";

  for (let i = 0; i < keys.length; i++) {
    if (FILE_TYPE_MAP[keys[i]].includes(mimeType)) {
      imagePath = keys[i];
    }
  }

  return (
    <img
      src={`/icons/fileTypes/${imagePath}`}
      alt=""
      width="32px"
      height="32px"
    />
  );
}

export function getErrorMessage(error: any) {
  let data = error?.response?.data;
  if (data?.errors && data.errors.length > 0) {
    return "Error: " + data.errors[0].msg;
  }

  if (data?.message && data.message.length > 0) {
    return "Error: " + data.message;
  }

  return "Error: Please try again.";
}
